import { useQuery } from "@tanstack/react-query";
import { callSymptoms } from "../utils/endPoints";
import Cookies from "js-cookie";

export const useApiMedicSymptoms = () => {
  const params: any = new URLSearchParams();
  params.append("token", Cookies.get("Medic_Token"));
  params.append("language", "en-gb");

  return useQuery({
    queryKey: ["apiMedic-symptoms"],
    queryFn: () => callSymptoms(params),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !!Cookies.get("Medic_Token"),
  });
};

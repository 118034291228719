import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { OneIcon, ThreeIcon, TwoIcon } from "../../../assets/icons";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ArrowForward, Close } from "@mui/icons-material";
import DemographicsInfo from "../../../components/DemographicsInfo";
import {
  Link,
  Outlet,
  matchPath,
  useLocation,
  useParams,
} from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  callAppointment,
  callEachPatientsListEndPoint,
} from "../../../utils/endPoints";
import DnaLoading from "../../../components/DnaLoading";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction="left"
      timeout={{ enter: 2000, exit: 400 }}
      ref={ref}
      {...props}
    />
  );
});

export const PTSWidgetStyle = styled("div")(({ theme }) => ({
  transition: "all 0.5s ease-out",
  width: "100%",
  minWidth: 160,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: 10,
  padding: 0,
}));

const PatientInfo = React.memo(function PatientInfo() {
  const [demographicsInfoOpen, setDemographicsInfoOpen] = React.useState(false);
  const params: any = useParams();

  const { data, isLoading, isError, failureReason }: any = useQuery({
    queryKey: ["appointment", params],
    queryFn: () => callAppointment(params),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });

  const {
    isError: patientIsError,
    isInitialLoading: patientLoading,
    data: patientData,
    failureReason: patientFailureReason,
  }: any = useQuery({
    queryKey: ["patients", data?.[0]?.puuid],
    queryFn: () => callEachPatientsListEndPoint(data?.[0]?.puuid),
    staleTime: 10000,
    enabled: !!data,
  });

  const handleClickDemographicsInfoOpen = () => {
    setDemographicsInfoOpen(true);
  };

  const handleDemographicsInfoClose = () => {
    setDemographicsInfoOpen(false);
  };

  if (isLoading || patientLoading) {
    return <DnaLoading />;
  }

  if (isError || patientIsError) {
    return (
      <h1>
        {isError ? failureReason?.message : patientFailureReason?.message ?? ""}
      </h1>
    );
  }

  return (
    <>
      <Box
        sx={{
          background: "#446CBA",
          marginBottom: -3,
          color: "white",
          padding: 2,
        }}
      >
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          spacing={0}
        >
          {" "}
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="PName"
                sx={{ background: "orange", height: 60, width: 60 }}
              >
                {patientData?.data?.fname[0]} {patientData?.data?.lname[0]}
              </Avatar>
              <Typography className="name" variant="subtitle1">
                {patientData?.data?.fname} {patientData?.data?.lname}
              </Typography>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography className="val" variant="caption">
                    <Typography
                      className="lbl"
                      sx={{ opacity: 0.6 }}
                      variant="caption"
                      component={"span"}
                    >
                      Patient ID:
                    </Typography>{" "}
                    {patientData?.data?.pid ?? "NA"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography className="val" variant="caption">
                    <Typography
                      className="lbl"
                      sx={{ opacity: 0.6 }}
                      variant="caption"
                      component={"span"}
                    >
                      Sex:
                    </Typography>{" "}
                    {patientData?.data?.sex ? patientData?.data?.sex : "NA"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Typography variant="body2" component={"div"}>
                  <Typography
                    variant="body2"
                    component={"span"}
                    sx={{ opacity: 0.6 }}
                  >
                    {" "}
                    M:{" "}
                  </Typography>
                  {patientData?.data?.status ? patientData?.data?.status : "NA"}
                </Typography>

                <Typography variant="body2" component={"div"}>
                  {" "}
                  <Typography
                    variant="body2"
                    component={"span"}
                    sx={{ opacity: 0.6 }}
                  >
                    {" "}
                    Ht:{" "}
                  </Typography>{" "}
                  {Math.round(patientData?.data?.height * 10) / 10 ?? "NA"}
                </Typography>

                <Typography variant="body2" component={"div"}>
                  {" "}
                  <Typography
                    variant="body2"
                    component={"span"}
                    sx={{ opacity: 0.6 }}
                  >
                    {" "}
                    Wt:{" "}
                  </Typography>{" "}
                  {Math.round(patientData?.data?.weight * 10) / 10 ?? "NA"}
                </Typography>
              </Box>
            </Box>
          </Grid>{" "}
          <Grid item xs={12} md={2}>
            <Button
              variant="text"
              startIcon={<ArrowForward />}
              className="whitetext xs"
              size="small"
              onClick={handleClickDemographicsInfoOpen}
            >
              View Demographics
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={demographicsInfoOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDemographicsInfoClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: 1010, // Set your width here
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {"Demographics"}{" "}
          <IconButton sx={{ padding: 0 }} onClick={handleDemographicsInfoClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DemographicsInfo />
        </DialogContent>
      </Dialog>
    </>
  );
});

export function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  let paths = pathname.split("/").length;
  let path = `/${pathname.split("/")[paths - 1]}`;

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, path);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export default function AppointmentHistory() {
  const [selectedTab, setSelectedTab] = useState("History");
  const handleChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const routeMatch = useRouteMatch([
    "/history",
    "/intake-form",
    "/consultation-panel",
  ]);
  const currentTab: any = routeMatch?.pattern?.path;

  useEffect(() => {
    if (currentTab === "/history") {
      setSelectedTab("History");
    } else if (currentTab === "/consultation-panel") {
      setSelectedTab("Consultation");
    } else {
      setSelectedTab("Intake Form");
    }
  }, [currentTab]);

  return (
    <>
      <PatientInfo />
      <TabContext value={selectedTab}>
        <TabList
          variant="fullWidth"
          className="ApptTabs"
          sx={{ padding: "20px 20px 0 20px", height: "auto" }}
          onChange={handleChange}
        >
          <Tab
            key={"1"}
            label={"History"}
            iconPosition="start"
            icon={<OneIcon />}
            value={"History"}
            component={Link}
            to="history"
          />
          <Tab
            key={"2"}
            label={"Intake Form"}
            iconPosition="start"
            icon={<TwoIcon />}
            value={"Intake Form"}
            component={Link}
            to="intake-form"
          />
          <Tab
            key={"3"}
            label={"Consultation"}
            iconPosition="start"
            icon={<ThreeIcon />}
            value={"Consultation"}
            component={Link}
            to="consultation-panel"
          />
        </TabList>

        <TabPanel className="ApptTabPanel" value={"History"}>
          <Outlet />
        </TabPanel>
        <TabPanel className="ApptTabPanel" value={"Intake Form"}>
          <Outlet />
        </TabPanel>
        <TabPanel className="ApptTabPanel" value={"Consultation"}>
          <Outlet />
        </TabPanel>
      </TabContext>
    </>
  );
}

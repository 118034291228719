import { Box, Typography, Card, CardHeader, CardContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { callPatientMedications } from "../../utils/endPoints";

const columnsMyMedications = [
  {
    field: "title",
    headerName: "Medicine",
    minWidth: 150,
  },
  {
    field: "doseDetails",
    headerName: "Strength/Dosage Details",
    width: 200,
    // renderCell: (params: any) => {
    //   return (
    //     <>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           flexWrap: "wrap",
    //           padding: 1,
    //           gap: 1,
    //         }}
    //       >
    //         {" "}
    //         <Typography variant="body2" component={"div"}>
    //           {params.row.doseDetails[0].strength}
    //         </Typography>{" "}
    //         <Typography variant="body2" component={"div"}>
    //           {params.row.doseDetails[1].dosage}
    //         </Typography>{" "}
    //         <Typography variant="body2" component={"div"}>
    //           {params.row.doseDetails[2].quanity}
    //         </Typography>{" "}
    //         <Typography variant="body2" component={"div"}>
    //           {params.row.doseDetails[3].refill}
    //         </Typography>{" "}
    //         <Typography variant="body2" component={"div"}>
    //           {params.row.doseDetails[4].directions}
    //         </Typography>{" "}
    //       </Box>
    //     </>
    //   );
    // },
  },
  {
    field: "date",
    headerName: "Start Date - End Date",
    minWidth: 150,
  },
];

export default function MyMedications() {
  const [medicationState, setMedicationState] = useState("current_med");
  const { pid } = useParams();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["medications", pid],
    queryFn: () => callPatientMedications(pid ?? ""),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });
  return (
    <>
      <Card>
        <CardHeader title=" My Medications" />
        <CardContent>
          <ButtonGroup style={{ marginBottom: 15 }}>
            <Button
              onClick={() => setMedicationState("current_med")}
              variant={
                medicationState === "current_med" ? "contained" : "outlined"
              }
              size="small"
            >
              Current Medications
            </Button>{" "}
            <Button
              onClick={() => setMedicationState("past_med")}
              variant={
                medicationState === "past_med" ? "contained" : "outlined"
              }
              size="small"
            >
              Past Medications
            </Button>
          </ButtonGroup>
          <div style={{ height: 300, width: "100%" }}>
            {isError ? (
              <Typography color="error" variant="h6">
                something went wrong!
              </Typography>
            ) : (
              <DataGrid
                rows={data ?? []}
                columns={columnsMyMedications}
                hideFooter
                autoPageSize
                columnHeaderHeight={30}
                getRowHeight={() => "auto"}
                loading={isLoading}
                getRowId={(row) => row?.id}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
}

import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  LinearProgress,
  Box,
} from "@mui/material";
import { FrmLbl } from "../../theme";
import { callSOAPDetails } from "../../utils/endPoints";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Dna } from "react-loader-spinner";

export default function SOAPNotes() {
  const params: any = useParams();
  const { data, isLoading, isError }: any = useQuery({
    queryKey: ["soap_notes", params],
    queryFn: () => callSOAPDetails(params),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });
  return (
    <>
      <Card>
        <CardHeader
          title={
            <>
              SOAP Notes{" "}
              <Typography
                color={"primary"}
                variant="caption"
                component={"span"}
              >
                (Observations written by clinician)
              </Typography>
            </>
          }
        />
        {isLoading ? (
          <Box>
            <LinearProgress />
          </Box>
        ) : isError ? (
          <Typography color="error" variant="h6">
            something went wrong!
          </Typography>
        ) : (
          <CardContent className="h220">
            <Grid
              container
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={3}
            >
              <Grid item xs={12} md={12}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    Date & Time:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.date ?? "NA"}
                  </Typography>
                </FrmLbl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    Subjective:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.subjective ?? "NA"}
                  </Typography>
                </FrmLbl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    Objective:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.objective ?? "NA"}
                  </Typography>
                </FrmLbl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    Assessment:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.assessment ?? "NA"}
                  </Typography>
                </FrmLbl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    Plan:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.plan ?? "NA"}
                  </Typography>
                </FrmLbl>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </>
  );
}

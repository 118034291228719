import { Symptom } from "../types";
import {
  getCall,
  medicGetCall,
  postCall,
  postCallForYSIDiagnosis,
  // postCallOfX,
} from "./apiCallServices";

// Login with username and password
export const callLoginApiEndPoint = (data: {} | undefined) => {
  return postCall("apis/authLogin.php", data);
};

// physician details
export const callPhysicianDetailsEndPoint = (params: string) => {
  return getCall(`apis/default/api/practitioner/${params}`);
};

// patient search
export const callAllPatientsListApiEndPoint = (params: string) => {
  return getCall("apis/default/api/patient?lname=" + params);
};

// patient timeline
export const callPatientTimeLineEndPoint = (params: URLSearchParams) => {
  return getCall("apis/timeline.php?" + params);
};

// specific patient details
export const callEachPatientsListEndPoint = (params: string) => {
  return getCall(`apis/default/api/patient/${params}`);
};

// all appointments
export const callAppointments = () => {
  return getCall(`apis/default/api/appointment`);
};

// review of systems
export const callReviewOfSystems = (params: URLSearchParams) => {
  return getCall(`apis/reviewOfSystems.php?` + params);
};

// specific appointment list
export const callAppointment = (params: { pc_eid: string; pid: string }) => {
  return getCall(
    `apis/default/api/patient/${params?.pid}/appointment/${params?.pc_eid}`
  );
};

// apis/default/api/immunization?patient_id=1
export const callImmunization = (params: URLSearchParams) => {
  return getCall(`apis/default/api/immunization?` + params);
};

// provider details
export const callProviderDetails = (params: string) => {
  return getCall(`apis/default/api/practitioner/${params}`);
};

// GET medications
export const callPatientMedications = (params: string) => {
  return getCall(`apis/default/api/patient/${params}/medication`);
};

// GET vitals
export const callPatientVitals = (params: { pc_eid: string; pid: string }) => {
  return getCall(
    `apis/default/api/patient/${params?.pid}/encounter/${params?.pc_eid}/vital`
  );
};

// get encounters
// export const callGetEncounter = (params: { pc_eid: string; pid: string }) => {
//   return getCall(`apis/default/api/patient/${params?.pid}/encounter`);
// };
// SOAP details
export const callSOAPDetails = (params: { pc_eid: string; pid: string }) => {
  return getCall(
    `apis/default/api/patient/${params?.pid}/encounter/${params?.pc_eid}/soap_note`
  );
};

// medical problems
export const callMedicalProblemDetails = (params: { puuid: string }) => {
  return getCall(`apis/default/api/patient/${params?.puuid}/medical_problem`);
};

// api medic symptoms
export const callSymptoms = (params: URLSearchParams): Promise<Symptom> => {
  return medicGetCall(`symptoms?${params}`);
};

export const callDiagnosis = (params: URLSearchParams) => {
  return medicGetCall(`diagnosis?${params}`);
};

// YSI MODEL APIS

// Diagnosis TOKEN API
export const callDiagnosisToken = (data: {} | undefined) => {
  return postCallForYSIDiagnosis(`getToken`, data);
};

// SymptomSearch
export const callSymptomSearch = (data: {} | undefined) => {
  return postCallForYSIDiagnosis(`SymptomSearch`, data);
};

// predict diagnosis
export const callDiagnosisPredict = (data: {} | undefined) => {
  return postCallForYSIDiagnosis(`DiseasePrediction`, data);
};

// Predict Lab tests & Image tests
export const callTestsPredict = (data: {} | undefined) => {
  return postCallForYSIDiagnosis(`TestSearch`, data);
};

// Predict Medications
export const callMedicationPredict = (data: {} | undefined) => {
  return postCallForYSIDiagnosis(`MedicationSearch`, data);
};

// Predict PreventiveMeasures
export const callPreventiveMeasuresPredict = (data: {} | undefined) => {
  return postCallForYSIDiagnosis(`PreventiveMeasures`, data);
};

// Summary
export const callPatientPredictSummary = (data: {} | undefined) => {
  return postCallForYSIDiagnosis(`Summary`, data);
};

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Grid,
  LinearProgress,
} from "@mui/material";
import PlusIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { theme } from "../../theme";
import { usePreventiveMeasures } from "../../queries/usePreventiveMeasures";

export default function PreventiveMeasures({
  selectedDiseaseId,
  setPreventiveMeasuresData,
  PreventiveMeasuresData,
}: any) {
  const checkPreventiveMeasuresIsPresent = (item: string) => {
    return PreventiveMeasuresData?.find((each: any) => each === item);
  };
  const { data, isInitialLoading, isError, error }: any =
    usePreventiveMeasures(selectedDiseaseId);
  return (
    <>
      <Card>
        <CardHeader title="Preventive Measures" />
        <CardContent>
          <Typography
            variant="body2"
            component={"div"}
            fontWeight={600}
            style={{
              color: theme.palette.primary.main,
              padding: "3px 0",
              borderBottom: "1px solid " + theme.palette.primary.main,
              marginBottom: 10,
            }}
          >
            Filtered based on the diagnosis:
          </Typography>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"flex-start"}
            spacing={1}
          >
            <Grid item xs={12}>
              <Box className="AddToList">
                {isInitialLoading ? (
                  <LinearProgress />
                ) : isError ? (
                  <Box>
                    <Typography variant="body2" component={"div"}>
                      {error?.message}
                    </Typography>
                  </Box>
                ) : data?.preventive_measures?.length > 0 ? (
                  data?.preventive_measures?.map((item: any) => (
                    <Box key={item}>
                      <Typography variant="caption" component={"div"}>
                        {item}
                      </Typography>
                      <Button
                        variant="text"
                        size="small"
                        className="xs"
                        startIcon={
                          checkPreventiveMeasuresIsPresent(item) ? (
                            <RemoveCircleIcon />
                          ) : (
                            <PlusIcon />
                          )
                        }
                        onClick={() => {
                          if (checkPreventiveMeasuresIsPresent(item)) {
                            setPreventiveMeasuresData(
                              PreventiveMeasuresData?.filter(
                                (each: any) => each !== item
                              )
                            );
                          } else {
                            setPreventiveMeasuresData((prev: any) => [
                              ...prev,
                              item,
                            ]);
                          }
                        }}
                      >
                        {checkPreventiveMeasuresIsPresent(item)
                          ? "Remove"
                          : "Add to RxPad"}
                      </Button>
                    </Box>
                  ))
                ) : (
                  <Box>
                    <Typography variant="caption" component={"div"}>
                      Not Available
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

import { useQuery } from "@tanstack/react-query";
import { callDiagnosisToken } from "../utils/endPoints";
import Cookies from "js-cookie";

export const useToken = () => {
  const bodyData = {
    username: "provider",
    password: "ritwik@123",
  };
  return useQuery({
    queryKey: ["diagnosis-token"],
    queryFn: () => callDiagnosisToken(bodyData),
    refetchInterval: 28 * 60 * 1000,
    select(data) {
      if (Cookies.get("DIAGNOSIS_TOKEN")) {
        Cookies.remove("DIAGNOSIS_TOKEN");
        Cookies.set("DIAGNOSIS_TOKEN", data?.access_token, {
          expires: new Date(new Date().getTime() + 28 * 60 * 1000), // expires in 28 minutes
        });
      } else {
        Cookies.set("DIAGNOSIS_TOKEN", data?.access_token, {
          expires: new Date(new Date().getTime() + 28 * 60 * 1000), // expires in 28 minutes
        });
      }
    },
  });
};

import { useQuery } from "@tanstack/react-query";
import { callPreventiveMeasuresPredict } from "../utils/endPoints";

export const usePreventiveMeasures = (queryString: string) => {
  const bodyData = {
    disease_id: queryString,
  };
  return useQuery({
    queryKey: ["PreventiveMeasures", queryString],
    queryFn: () => callPreventiveMeasuresPredict(bodyData),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !!queryString,
  });
};

import React, { useState } from "react";
import SnackBar from "../../components/SnackBar";
import YSIcon from "../../assets/images/ys-icon.png";
import { Dna } from "react-loader-spinner";
import MedicationIcon from "@mui/icons-material/Medication";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import DnaLoading from "../../components/DnaLoading";
import { AlertType } from "../SignIn";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import useDebounce from "../../hooks/useDebounce";
import { useSymptomSearch } from "../../queries/useSymptomSearch";
import useDiagnosis from "../../queries/useDiagnosis";
import { useTests } from "../../queries/useTests";
import { useMedications } from "../../queries/useMedications";
import { useToken } from "../../queries/useToken";
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
// Styles
const DPStyle = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: 50,
  padding: 20,
  gap: 6,
  "& .dna-wrapper": {
    margin: "auto",
  },
}));
const MedicationsStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginTop: 2,
  padding: 20,
  paddingTop: 0,
  gap: 6,
  borderRadius: 5,
  "& .nodata": {
    height: 100,
    background: "none !important",
    "& .MuiTypography-root": {
      lineHeight: "100px",
      textAlign: "center",
      width: "100%",
    },
  },
  "& .title": {
    padding: 5,
  },
  "& .list": {
    padding: 10,
    width: "100%",
    border: "1px solid #ddd",
    borderRadius: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    gap: 6,
    "& .MuiBox-root": {
      alignItems: "center",
      display: "flex",
      borderRadius: 6,
      background: "#f2f2f2",
      padding: "6px 10px",
      "& svg": {
        marginRight: 10,
        color: "#4b5ba4c2",
      },
    },
  },
}));
const LabImageStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "stretch",
  marginTop: 2,
  padding: 20,
  paddingTop: 0,
  paddingBottom: 10,
  gap: 10,
  borderRadius: 5,
  "& .LabTest, & .ImageTest": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 6,
    width: "100%",
  },
  "& .title": {
    padding: 5,
  },
  "& .nodata": {
    height: 100,
    background: "none !important",
    "& .MuiTypography-root": {
      lineHeight: "100px",
      textAlign: "center",
      width: "100%",
    },
  },
  "& .list": {
    padding: 10,
    width: "100%",
    height: "100%",
    border: "1px solid #ddd",
    borderRadius: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    gap: 6,
    "& .MuiBox-root": {
      display: "flex",
      borderRadius: 6,
      background: "#f2f2f2",
      padding: "6px 10px",
      "& svg": {
        marginRight: 10,
        color: "#4b5ba4c2",
      },
    },
  },
}));
const SymtomsListStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: 2,
  padding: "6px 10px",
  gap: 6,
  borderRadius: 5,

  "&.head": {
    background: "#d3d9e7",
  },
  "&.list": {
    background: "#E9ECF2",
  },
  "& .text": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .disease ": {
      width: "70%",
    },
    "&   .accuracy ": {
      width: "30%",
    },
    "& .button ": {
      minWidth: 100,
      "& button.Deselect ": {
        filter: "saturate(10)",
      },
      "& button ": {
        height: 22,
      },
    },
  },
}));
// App Bar Styles
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: "linear-gradient(181deg, #1A4176 0%,  #092852 50%, #1A4176 100%)",
  [theme.breakpoints.down("md")]: {
    boxShadow: "none",
  },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    [theme.breakpoints.up("md")]: {
      width: `100%`,
    },
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Prediction() {
  const [selectedOptions, setSelectedOptions] = useState<
    Array<{
      icd_code: string;
      symptom_id: string;
      symptom_name: string;
    }>
  >([]);
  const [selectedDiseaseId, setSelectedDiseaseId] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [alertState, setAlertState] = useState<AlertType>({
    message: "",
    open: false,
    variant: "warning",
  });

  const handleOptionChange = (event: React.SyntheticEvent, newValue: any) => {
    setSelectedOptions(newValue);
    if (selectedOptions.length === 0) {
      setSelectedDiseaseId("");
    } else if (newValue.length > selectedOptions.length) {
      setSelectedDiseaseId("");
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    // Handle input changes if needed
    setQuery(newInputValue);
  };

  const { isError, isInitialLoading, failureReason }: any = useToken();

  const debounceQuery = useDebounce(query, 1000);

  const { data: mockOptions, isFetching } = useSymptomSearch(debounceQuery);

  const {
    data: predictions,
    isInitialLoading: isLoading,
    error: diagnosisError,
    failureReason: diagnosisErrorMsg,
  }: any = useDiagnosis(
    selectedOptions.map((predictions) => predictions.symptom_name).join(",")
  );

  const {
    data: testsData,
    isInitialLoading: testsLoading,
    error,
    failureReason: testFailure,
  }: any = useTests(selectedDiseaseId);

  const {
    data: medicationsData,
    isInitialLoading: medicationLoading,
    error: medicationsError,
    failureReason: medicationFailureReason,
  }: any = useMedications(selectedDiseaseId);

  if (isInitialLoading) {
    return <DnaLoading />;
  }

  if (isError) {
    return <h1>{failureReason?.message ?? ""}</h1>;
  }

  return (
    <>
      {/* Nav Bar */}
      <AppBar position="fixed">
        <Toolbar
          sx={{
            pr: "24px",
          }}
        >
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{
              gap: 1,
              flexGrow: 1,
              margin: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={YSIcon}
              className="ysicon"
              height={30}
              alt="YSI-Icon"
              loading="lazy"
            />{" "}
            Provider App
          </Typography>
          <Box></Box>
        </Toolbar>
      </AppBar>

      <DPStyle>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="subtitle1"
              fontWeight={550}
              marginTop={1}
              marginBottom={1}
              textAlign={"left"}
              width={"100%"}
              color={"primary"}
            >
              Diagnosis Prediction
            </Typography>
            <Box>
              <Autocomplete
                multiple
                value={selectedOptions}
                loading={isFetching ? true : false}
                loadingText={"Loading..."}
                onChange={handleOptionChange}
                noOptionsText={"Search Symptoms..."}
                getOptionLabel={(option) => option?.symptom_name}
                onInputChange={handleInputChange}
                options={mockOptions ?? []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <div key={`${option}-${index}`}>
                      <Chip
                        label={option?.symptom_name}
                        {...getTagProps({ index })}
                      />
                    </div>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search symptoms..."
                    variant="outlined"
                  />
                )}
              />
            </Box>

            {/* Diseases */}
            {isLoading && (
              <Dna
                visible={true}
                height="70"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            )}
            {diagnosisError && (
              <Typography color="error">
                {diagnosisErrorMsg?.message}
              </Typography>
            )}
            {predictions && (
              <SymtomsListStyle className="head">
                <Box className="text">
                  <Typography
                    variant="body2"
                    component={"div"}
                    className="disease"
                  >
                    <Typography
                      fontWeight={600}
                      variant="subtitle2"
                      color="primary"
                    >
                      Disease
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body2"
                    component={"div"}
                    className="accuracy"
                  >
                    <Typography
                      fontWeight={600}
                      variant="subtitle2"
                      color="primary"
                    >
                      Accuracy
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body2"
                    component={"div"}
                    className="button"
                  ></Typography>
                </Box>
              </SymtomsListStyle>
            )}
            {predictions?.map((prediction: any) => (
              <SymtomsListStyle key={prediction?.disease_id} className="list">
                <CoronavirusIcon fontSize="small" color="primary" />
                <Box className="text">
                  <Typography
                    variant="body2"
                    component={"div"}
                    className="disease"
                  >
                    {prediction?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    component={"div"}
                    className="accuracy"
                  >
                    ({prediction?.accuracy})
                  </Typography>
                  <Typography
                    variant="body2"
                    component={"div"}
                    className="button"
                  >
                    <Button
                      onClick={() => {
                        setSelectedDiseaseId(
                          selectedDiseaseId === prediction?.disease_id
                            ? ""
                            : prediction?.disease_id
                        );
                      }}
                      type="button"
                      variant="contained"
                      color="primary"
                      size="small"
                      className={
                        selectedDiseaseId === prediction?.disease_id
                          ? "Deselect"
                          : "Select"
                      }
                    >
                      {selectedDiseaseId === prediction?.disease_id
                        ? "Deselect"
                        : "Select"}
                    </Button>
                  </Typography>
                </Box>
              </SymtomsListStyle>
            ))}
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                {/* Tests */}
                {(testsLoading || medicationLoading) && (
                  <Box marginX={3} marginY={2}>
                    <Dna
                      visible={true}
                      height="40"
                      width="50"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                  </Box>
                )}
                {error && predictions && (
                  <Typography color="error">
                    {testFailure?.response?.data?.error}
                  </Typography>
                )}
                {testsData && predictions && (
                  <LabImageStyle>
                    <Box className="LabTest">
                      <Box className="title">
                        <Typography
                          fontWeight={600}
                          variant="subtitle2"
                          color="primary"
                          component={"div"}
                        >
                          Lab Tests
                        </Typography>
                      </Box>
                      <Box className="list">
                        {testsData?.lab_tests?.length > 0 ? (
                          testsData?.lab_tests?.map(
                            (item: string, index: number) => (
                              <Box key={index}>
                                <AssignmentTurnedInIcon fontSize="small" />{" "}
                                <Typography variant="body2"> {item}</Typography>
                              </Box>
                            )
                          )
                        ) : (
                          <Box>
                            <Typography variant="body2">
                              Not Available
                            </Typography>
                          </Box>
                        )}
                      </Box>{" "}
                    </Box>
                    <Box className="ImageTest">
                      {" "}
                      <Box className="title">
                        <Typography
                          fontWeight={600}
                          variant="subtitle2"
                          color="primary"
                          component={"div"}
                        >
                          Image Tests
                        </Typography>
                      </Box>
                      <Box className="list">
                        {testsData?.image_tests?.length > 0 ? (
                          testsData?.image_tests?.map(
                            (item: string, index: number) => (
                              <Box key={index}>
                                <AssignmentTurnedInIcon fontSize="small" />{" "}
                                <Typography variant="body2"> {item}</Typography>
                              </Box>
                            )
                          )
                        ) : (
                          <Box className="nodata">
                            <Typography variant="body2">
                              Not Available
                            </Typography>
                          </Box>
                        )}
                      </Box>{" "}
                    </Box>
                  </LabImageStyle>
                )}
              </Grid>
              <Grid item xs={12}>
                {/* Medications */}
                {medicationsError && predictions && (
                  <Typography color="error">
                    {medicationFailureReason?.response?.data?.error}
                  </Typography>
                )}
                {medicationsData && predictions && (
                  <MedicationsStyle>
                    <Box className="title">
                      <Typography
                        fontWeight={600}
                        variant="subtitle2"
                        color="primary"
                        component={"div"}
                      >
                        Medications
                      </Typography>
                    </Box>
                    <Box className="list">
                      {medicationsData?.length > 0 ? (
                        medicationsData?.map(
                          (
                            item: { med_name: string; med_type: string },
                            index: number
                          ) => (
                            <Box key={index}>
                              <MedicationIcon fontSize="small" />{" "}
                              <Typography variant="body2">
                                {item?.med_name} - {item?.med_type}
                              </Typography>
                            </Box>
                          )
                        )
                      ) : (
                        <Box className="nodata">
                          <Typography variant="body2">Not Available</Typography>
                        </Box>
                      )}
                    </Box>
                  </MedicationsStyle>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DPStyle>
      <SnackBar
        message={alertState.message}
        variant={alertState.variant}
        open={alertState.open}
        handleClose={() => {
          setAlertState((prevState: any) => {
            return { ...prevState, open: false };
          });
        }}
      />
    </>
  );
}

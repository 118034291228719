import CheckIcon from "@mui/icons-material/Done";
import UnCheckIcon from "@mui/icons-material/Clear";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  LinearProgress,
  Box,
} from "@mui/material";
import { IconLbl } from "../../theme";
import { callReviewOfSystems } from "../../utils/endPoints";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Dna } from "react-loader-spinner";

export default function ReviewOfSystems() {
  const param: any = useParams();
  const params = new URLSearchParams({
    pid: param?.pid,
    encounter: param?.pc_eid,
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ["review_0f_systems", param?.pid],
    queryFn: () => callReviewOfSystems(params),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });
  return (
    <>
      <Card>
        <CardHeader title="Review Of Systems" />
        {isLoading ? (
          <Box>
            <LinearProgress />
          </Box>
        ) : isError ? (
          <Typography color="error" variant="h6">
            something went wrong!
          </Typography>
        ) : (
          <CardContent className="h220">
            <Grid
              container
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={3}
            >
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.weight_change === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Weight Change
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.weakness === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Weakness
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.fatigue === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Fatigue
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.anorexia === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Anorexia
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.fever === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Fever
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.chills === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Chills
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.night_sweats === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Night Sweats
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.insomnia === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Insomnia
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.irritability === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Irritability
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.heat_or_cold === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Heat Or Cold
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.intolerance === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Intolerance
                  </Typography>
                </IconLbl>
              </Grid>
              <Grid item xs={6} md={4}>
                <IconLbl>
                  <Typography variant="body2" component={"div"}>
                    {data?.response?.[0]?.change_in_vision === "YES" ? (
                      <CheckIcon />
                    ) : (
                      <UnCheckIcon />
                    )}{" "}
                    Change In Vision
                  </Typography>
                </IconLbl>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </>
  );
}

import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  ChainIcon,
  ClinicEditIcon,
  ClinicIcon,
  EmailOIcon,
  LocationIcon,
  PhoneIcon,
  PatientGroupIcon,
  ReviewsIcon,
  RatingIcon,
} from "../../assets/icons";
import { styled } from "@mui/material/styles";
import doctorimg from "../../assets/images/doctor-img.svg";
import { StyledIcon } from "../../components/AppWidgetSummary";
import { useQuery } from "@tanstack/react-query";
import { callPhysicianDetailsEndPoint } from "../../utils/endPoints";
import DnaLoading from "../../components/DnaLoading";

const PrescriberDetailsStyle = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: 160,
  height: "100vh",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  marginTop: 2,
  padding: 20,
  gap: 6,
  boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)",
  borderTopRightRadius: 20,
  borderTopLeftRadius: 20,
  [theme.breakpoints.up("md")]: {},
  [theme.breakpoints.down("sm")]: {
    overflow: "auto",
  },
}));

const IconText = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: 20,
  gap: 10,
  borderBottom: "1px solid rgba(26, 65, 118, 0.25)",
  "& .MuiBox-root": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 2,
  },
  "& a": {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  "& .Box": {
    padding: 10,
    borderRadius: 10,
    border: "1px solid #ccc",
  },
}));
const BoxStyle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  padding: 20,
  gap: 10,
  background: "#ffffff47",
  [theme.breakpoints.up("md")]: {
    minHeight: 105,
  },
  borderRadius: 10,
  marginTop: 5,
  border: "1px solid rgba(26, 65, 118, 0.25)",
  "& .icon": {
    display: "flex",
    gap: 10,
    "& .MuiTypography-body2": {
      marginTop: 3,
    },
  },
  "& .MuiTimelineDot-root": {
    margin: 8,
  },
}));

export function DrInfo({ data }: any) {
  const { fname, lname, mname, specialty, physician_title, organization } =
    data;
  return (
    <>
      <Box
        sx={{
          background: "#446CBA",
          marginBottom: -2,
          paddingTop: 1,
          paddingBottom: 4,
          display: "flex",
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <img src={doctorimg} alt="doc" />
        <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
          <Typography className="name" variant="h5">
            {fname} {mname} {lname}
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                className="lbl"
                sx={{ opacity: 0.6 }}
                variant="caption"
              >
                {specialty}
              </Typography>
              <Typography className="val" variant="caption">
                {physician_title},
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <ClinicIcon />
              <Typography maxWidth={180} variant="body2">
                {organization}
              </Typography>{" "}
              {/* <ClinicEditIcon fontSize="small" /> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="TopLeftRightRadius"></Box>
    </>
  );
}
export default function PrescriberProfile() {
  const { data, isLoading, error, isError }: any = useQuery({
    queryKey: ["physician", localStorage.getItem("providerId") ?? ""],
    queryFn: () =>
      callPhysicianDetailsEndPoint(
        localStorage.getItem("providerId")?.toString() ?? ""
      ),
  });

  if (isLoading) {
    return <DnaLoading />;
  }

  if (isError) {
    return <h1>{error?.message ?? ""}</h1>;
  }
  return (
    <>
      <DrInfo data={data?.data} />
      <PrescriberDetailsStyle>
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"top"}
          sx={{
            borderBottom: "1px solid rgba(26, 65, 118, 0.25)",
            paddingBottom: 1,
          }}
        >
          <Grid item xs={3} sm={3} md={3} textAlign={"center"}>
            <StyledIcon className="blue">
              <PatientGroupIcon fontSize="large" />
            </StyledIcon>
            <Typography color="primary" fontWeight={400} variant="h5">
              {data?.patient_count}
            </Typography>
            <Typography variant="caption">Patients</Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} textAlign={"center"}>
            <StyledIcon className="blue">
              <RatingIcon />
            </StyledIcon>
            <Typography color="primary" fontWeight={400} variant="h5">
              xx
            </Typography>
            <Typography variant="caption">Rating</Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} textAlign={"center"}>
            <StyledIcon className="blue">
              <ChainIcon />
            </StyledIcon>
            <Typography color="primary" fontWeight={400} variant="h5">
              xx
            </Typography>
            <Typography variant="caption">Years Exp.</Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} textAlign={"center"}>
            <StyledIcon className="blue">
              <ReviewsIcon />
            </StyledIcon>
            <Typography color="primary" fontWeight={400} variant="h5">
              xx
            </Typography>
            <Typography variant="caption">Reviews</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"top"}
          spacing={1}
        >
          <Grid item xs={12} md={6}>
            <IconText>
              <LocationIcon fontSize="large" />
              <Typography variant="body1" component={"div"}>
                {data?.data?.street}, {data?.data?.city}, {data?.data?.state},
                Country: US - {data?.data?.zip}
              </Typography>
            </IconText>{" "}
          </Grid>
          <Grid item xs={12} md={3}>
            <IconText>
              <PhoneIcon fontSize="large" />
              <Typography variant="body1" component={"div"}>
                {data?.data?.phonew1 ?? "NA"}, {data?.data?.phonew2 ?? ""}
              </Typography>
            </IconText>{" "}
          </Grid>
          <Grid item xs={12} md={3}>
            <IconText>
              <EmailOIcon fontSize="large" />
              <Typography variant="body1" component={"div"}>
                <a href={data?.data?.google_signin_email}>
                  {" "}
                  {data?.data?.google_signin_email ?? "NA"}
                </a>
              </Typography>
            </IconText>{" "}
          </Grid>{" "}
        </Grid>
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"top"}
          spacing={1}
        >
          <Grid item xs={12} md={8}>
            <Typography
              marginTop={2}
              variant="body1"
              color={"primary"}
              fontWeight={600}
            >
              About Me
            </Typography>
            <BoxStyle>
              <Typography variant="body1">
                {data?.data?.info ?? "Not Available"}
              </Typography>
            </BoxStyle>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              marginTop={2}
              variant="body1"
              color={"primary"}
              fontWeight={600}
            >
              Working Time
            </Typography>
            <BoxStyle>
              <Typography variant="body1">
                Monday - Friday, 08:00 AM - 20:00 PM (static data)
              </Typography>
            </BoxStyle>
          </Grid>
        </Grid>
      </PrescriberDetailsStyle>
    </>
  );
}

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Grid,
  ListItemIcon,
} from "@mui/material";
import { theme } from "../../theme";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
export default function PrescriptionPad({
  medicationsData,
  diagnosisTestData,
  PreventiveMeasuresData,
}: any) {
  return (
    <>
      <Card>
        <CardHeader
          title="Prescription Pad"
          action={
            <Button variant="text" size="small">
              View Past
            </Button>
          }
        />{" "}
        <CardContent>
          <Grid
            container
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"top"}
            spacing={1}
          >
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                component={"div"}
                fontWeight={600}
                style={{
                  color: theme.palette.primary.main,
                  padding: "3px 0",
                }}
              >
                Rx Pad
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 6,
                  padding: 10,
                  borderRadius: 6,
                  marginTop: 5,
                  marginBottom: 10,
                  border: "1px solid #ccc",
                }}
              >
                {medicationsData?.length > 0 ? (
                  medicationsData?.map((data: any) => (
                    <>
                      <Typography variant="body1" component={"div"}>
                        <strong>{data?.drugName}</strong>
                      </Typography>
                      <Typography
                        variant="body2"
                        component={"div"}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 5,
                          marginLeft: 10,
                        }}
                      >
                        <Typography variant="body2" component={"div"}>
                          <Typography
                            color={"primary"}
                            variant="caption"
                            component={"span"}
                          >
                            Dosage:
                          </Typography>{" "}
                          {data?.rxDirectionsTime}
                        </Typography>
                        <Typography variant="body2" component={"div"}>
                          <Typography
                            color={"primary"}
                            variant="caption"
                            component={"span"}
                          >
                            Strength:
                          </Typography>{" "}
                          {data?.rxMedicineUnits} mg
                        </Typography>
                        <Typography variant="body2" component={"div"}>
                          <Typography
                            color={"primary"}
                            variant="caption"
                            component={"span"}
                          >
                            Quantity:
                          </Typography>{" "}
                          {data?.rxQuantity}
                        </Typography>
                        <Typography variant="body2" component={"div"}>
                          <Typography
                            color={"primary"}
                            variant="caption"
                            component={"span"}
                          >
                            Refill:
                          </Typography>{" "}
                          {data?.rxRefills}
                        </Typography>
                        <Typography variant="body2" component={"div"}>
                          <Typography
                            color={"primary"}
                            variant="caption"
                            component={"span"}
                          >
                            Directions:
                          </Typography>{" "}
                          {data?.rxDirections}
                        </Typography>
                      </Typography>
                    </>
                  ))
                ) : (
                  <>
                    {" "}
                    <List className="list">
                      <ListItem>
                        <ListItemIcon>
                          <ChevronRightOutlinedIcon />
                        </ListItemIcon>{" "}
                        <ListItemText>
                          <Typography variant="body2" component={"div"}>
                            No Drugs Selected
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body2"
                component={"div"}
                fontWeight={600}
                style={{
                  color: theme.palette.primary.main,
                  padding: "3px 0",
                }}
              >
                Diagnosis Tests
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 6,
                  padding: 10,
                  borderRadius: 6,
                  border: "1px solid #ccc",
                  marginTop: 5,
                  marginBottom: 10,
                }}
              >
                <List className="list">
                  {diagnosisTestData?.length > 0 ? (
                    diagnosisTestData?.map((data: any) => (
                      <ListItem>
                        <ListItemIcon>
                          <ChevronRightOutlinedIcon />
                        </ListItemIcon>{" "}
                        <ListItemText>
                          <Typography variant="body2" component={"div"}>
                            {data?.name}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemIcon>
                        <ChevronRightOutlinedIcon />
                      </ListItemIcon>{" "}
                      <ListItemText>
                        <Typography variant="body2" component={"div"}>
                          No Tests Selected
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </Box>
              <Typography
                variant="body2"
                component={"div"}
                fontWeight={600}
                style={{
                  color: theme.palette.primary.main,
                  padding: "3px 0",
                }}
              >
                Preventive Measures
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 6,
                  padding: 10,
                  borderRadius: 6,
                  border: "1px solid #ccc",
                  marginTop: 5,
                }}
              >
                <List className="list">
                  {PreventiveMeasuresData?.length > 0 ? (
                    PreventiveMeasuresData?.map((preventiveMeasure: any) => (
                      <ListItem>
                        <ListItemIcon>
                          <ChevronRightOutlinedIcon />
                        </ListItemIcon>{" "}
                        <ListItemText>
                          <Typography variant="body2" component={"div"}>
                            {preventiveMeasure}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemIcon>
                        <ChevronRightOutlinedIcon />
                      </ListItemIcon>{" "}
                      <ListItemText>
                        <Typography variant="body2" component={"div"}>
                          No Measures Selected
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

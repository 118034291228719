import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MedicationIcon from "@mui/icons-material/Medication";
import PlusIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PrescriptionPopup from "../PrescriptionPopup";
import { useMedications } from "../../queries/useMedications";
import { theme } from "../../theme";

export default function Medications({
  medicationsData,
  setMedicationsData,
  selectedDiseaseId,
}: any) {
  const [medName, setMedName] = useState("");
  const [rxOpen, setRxOpen] = useState(false);
  const handleRxClose = () => {
    setRxOpen(false);
    setMedName("");
  };
  const handleRxOpen = () => {
    setRxOpen(true);
  };
  const checkMedicationIsPresent = (medName: any) => {
    return medicationsData?.find((med: any) => med?.drugName === medName);
  };

  const { data, isInitialLoading, error, isError }: any =
    useMedications(selectedDiseaseId);

  return (
    <>
      <Card>
        <CardHeader
          title="Medications"
          action={
            <Button variant="text" size="small">
              Previous Medications
            </Button>
          }
        />{" "}
        <CardContent>
          <Typography
            variant="body2"
            component={"div"}
            fontWeight={600}
            style={{
              color: theme.palette.primary.main,
              padding: "3px 0",
              borderBottom: "1px solid " + theme.palette.primary.main,
              marginBottom: 10,
            }}
          >
            Filtered based on the diagnosis:
          </Typography>
          <Box className="AddToList">
            {isInitialLoading ? (
              <Box>
                <LinearProgress />
              </Box>
            ) : isError ? (
              <Box>
                <Typography variant="body2" component={"div"}>
                  {error?.message}
                </Typography>
              </Box>
            ) : data?.length > 0 ? (
              data?.map((options: any) => (
                <Box key={options?.med_name}>
                  <MedicationIcon color="primary" fontSize="small" />
                  <Typography variant="caption" component={"div"}>
                    {options?.med_name} - {options?.med_type}
                  </Typography>
                  <Button
                    variant="text"
                    size="small"
                    className="xs"
                    // disabled={medicationsData?.some(
                    //   (option: any) => option.name === options?.med_name
                    // )}
                    startIcon={
                      checkMedicationIsPresent(options?.med_name) ? (
                        <RemoveCircleIcon />
                      ) : (
                        <PlusIcon />
                      )
                    }
                    onClick={() => {
                      if (checkMedicationIsPresent(options?.med_name)) {
                        setMedicationsData(
                          medicationsData?.filter(
                            (each: any) => each?.drugName !== options?.med_name
                          )
                        );
                      } else {
                        handleRxOpen();
                        setMedName(options?.med_name);
                      }
                    }}
                  >
                    {checkMedicationIsPresent(options?.med_name)
                      ? "Remove"
                      : "Add to RxPad"}
                  </Button>
                </Box>
              ))
            ) : (
              <Box>
                <Typography variant="caption" component={"div"}>
                  Not Available
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <PrescriptionPopup
        setMedicationsData={setMedicationsData}
        medicationsData={medicationsData}
        medName={medName}
        rxOpen={rxOpen}
        handleRxClose={handleRxClose}
      />
    </>
  );
}

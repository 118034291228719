import { Search } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Link } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { callAllPatientsListApiEndPoint } from "../../utils/endPoints";
import useDebounce from "../../hooks/useDebounce";
import DnaLoading from "../../components/DnaLoading";
import PatientCard from "../../components/PatientCard";
import moment from "moment";
import { Typography } from "@mui/material";

export default function Patients({ heading }: any) {
  const [patientLastName, setPatients] = useState("");
  const debouncedLastName = useDebounce(patientLastName, 1000);

  const { data, isLoading, isError, failureReason, isPreviousData }: any =
    useQuery({
      queryKey: ["patients", { lname: debouncedLastName }],
      queryFn: () => callAllPatientsListApiEndPoint(debouncedLastName),
      placeholderData: keepPreviousData,
      staleTime: 10000,
    });

  if (isLoading) {
    return <DnaLoading />;
  }

  if (isError) {
    return (
      <>
        <h1>{failureReason.message}</h1>
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          background: "#446CBA",
          marginBottom: -2,
          padding: 3,
          paddingBottom: 5,
          display: "flex",
          gap: 2,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          color: "white",
        }}
      >
        {heading ? (
          <Typography variant="subtitle1"> {heading}</Typography>
        ) : (
          <Typography variant="subtitle1">Patient Search</Typography>
        )}
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          margin="normal"
          required
          type="search"
          fullWidth
          id="search"
          placeholder="Enter patient last name"
          name="patientSearch"
          autoComplete="email"
          autoFocus
          value={patientLastName}
          onChange={(e) => {
            setPatients(e.target.value);
          }}
        />
      </Box>
      <Box className="TopLeftRightRadius"></Box>
      <Box
        sx={{
          padding: 3,
          height: "100vh",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{ opacity: isPreviousData ? 0.5 : 1 }}
        >
          {data?.data?.map(
            ({ id, fname, lname, title, sex, date, uuid, DOB, pid }: any) => (
              <Grid
                key={id}
                item
                xs={12}
                md={4}
                style={{ textDecoration: "none", color: "inherit" }}
                component={Link}
                to={`${uuid}/${pid}`}
              >
                <PatientCard
                  key={id}
                  id={id}
                  fname={fname}
                  lname={lname}
                  title={title}
                  sex={sex}
                  age={moment().diff(DOB, "years")?.toString() + " Yrs"}
                  date={date}
                />
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </>
  );
}

import React, { useState } from "react";
import { TextField, Grid, Slide } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import { TransitionProps } from "@mui/material/transitions";
import { Select, MenuItem, FormControl, Typography } from "@mui/material";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction="left"
      timeout={{ enter: 2000, exit: 400 }}
      ref={ref}
      {...props}
    />
  );
});
export default function RecallsPopup(props: any) {
  const [formData, setFormData] = useState({
    patientName: "",
    recallDate: "",
    recallReason: "",
    referringDoctor: "",
    referredDoctor: "",
    referralReason: "",
    rxStartingDate: "",
    rxProviderName: "",
    rxQuantity: "",
    rxMedicineUnits: "",
    rxMedicineUnitsType: "",
    rxDirections: "",
    rxDirectionsMedType: "",
    rxDirectionsType: "",
    rxDirectionsTime: "",
    rxRefills: "",
    rxRefillsCount: "",
    rxNotes: "",
  });

  const handleRecallsChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRecallsSubmit = (e: any) => {
    e.preventDefault();
    console.log(formData);
  };
  return (
    <>
      <Dialog
        open={props.recallsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleRecallsClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Patient Recalls"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <form onSubmit={handleRecallsSubmit}>
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                spacing={1}
              >
                <Grid item xs={12}>
                  <Typography variant="body2" component={"label"}>
                    Patient Name
                  </Typography>
                  <TextField
                    name="patientName"
                    fullWidth
                    value={formData.patientName}
                    onChange={handleRecallsChange}
                    margin="normal"
                    required
                  />{" "}
                </Grid>{" "}
                <Grid item xs={12}>
                  {" "}
                  <Typography variant="body2" component={"label"}>
                    Recall Date
                  </Typography>
                  <TextField
                    name="recallDate"
                    type="date"
                    fullWidth
                    value={formData.recallDate}
                    onChange={handleRecallsChange}
                    margin="normal"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />{" "}
                </Grid>{" "}
                <Grid item xs={12}>
                  <Typography variant="body2" component={"label"}>
                    Recall Reason
                  </Typography>
                  <FormControl fullWidth margin="normal" required>
                    <Select
                      name="recallReason"
                      value={formData.recallReason}
                      onChange={handleRecallsChange}
                    >
                      <MenuItem value="Appointment Reminder">
                        Appointment Reminder
                      </MenuItem>
                      <MenuItem value="Test Results">Test Results</MenuItem>
                      <MenuItem value="Follow-up">Follow-up</MenuItem>
                    </Select>
                  </FormControl>{" "}
                </Grid>{" "}
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={props.handleRecallsClose}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { Grid, Typography } from "@mui/material";
import React from "react";

function CompletedDetails() {
  return (
    <Grid
      container
      height={"85vh"}
      flex={1}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography textAlign={"center"} variant="h3" color={"primary"}>
        Coming soon
      </Typography>
    </Grid>
  );
}

export default CompletedDetails;

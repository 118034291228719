import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import SignIn from "./pages/SignIn";
import RootLayout from "./layouts/RootLayout";
import Dashboard from "./pages/Dashboard";
import Appointments from "./pages/Appointments";
import Patients from "./pages/Patients";
import Prescriptions from "./pages/Prescriptions";
import Notifications from "./pages/Notifications";
import Help from "./pages/Help";
import Reports from "./pages/Reports";
import NextVisits from "./pages/NextVisits";
import FlowBoard from "./pages/FlowBoard";
import AddressBook from "./pages/AddressBook";
import PatientDetails from "./pages/PatientDetails";
import PrescriberProfile from "./pages/PrescriberProfile";
import AppointmentHistory from "./pages/Appointments/AppointmentHistory";
import NotFound from "./components/NotFound";
import HistoryPanel from "./pages/Appointments/AppointmentHistory/HistoryPanel";
import IntakeFormPanel from "./pages/Appointments/AppointmentHistory/IntakeFormPanel";
import ConsultationPanel from "./pages/Appointments/AppointmentHistory/ConsultationPanel";
import Upcoming from "./pages/Appointments/Upcoming";
import Completed from "./pages/Appointments/Completed";
import OpenSlots from "./pages/Appointments/OpenSlots";
import Recalls from "./pages/Appointments/Recalls";
import CompletedDetails from "./pages/Appointments/Completed/CompletedDetails";
import Prediction from "./pages/Prediction";
import MedicPredictions from "./pages/MedicPredictions";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route index path="login" element={<SignIn />} />
          <Route path="*" element={<Navigate to="login" />} />
          <Route path="/" element={<Navigate to="login" />} />
        </Route>

        <Route path="/app" element={<RootLayout />}>
          <Route index path="/app" element={<Navigate to="home" />} />
          <Route path="home" element={<Dashboard />} />
          <Route path="appointments" element={<Appointments />}>
            <Route index path="upcoming" element={<Upcoming />} />
            <Route path="completed" element={<Completed />} />
            <Route path="open-slots" element={<OpenSlots />} />
            <Route path="recalls" element={<Recalls />} />
          </Route>
          <Route
            path="appointments/upcoming/:pc_eid/:pid/:puuid"
            element={<AppointmentHistory />}
          >
            <Route index path="history" element={<HistoryPanel />} />
            <Route path="intake-form" element={<IntakeFormPanel />} />
            <Route path="consultation-panel" element={<ConsultationPanel />} />
          </Route>
          <Route
            path="appointments/completed/:pc_eid/:pid/:puuid"
            element={<CompletedDetails />}
          />
          <Route path="patients" element={<Patients />} />
          <Route path="patients/:uuid/:pid" element={<PatientDetails />} />
          <Route path="prescriptions" element={<Prescriptions />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="help" element={<Help />} />
          <Route path="reports" element={<Reports />} />
          <Route path="address-book" element={<AddressBook />} />
          <Route path="flow-board" element={<FlowBoard />} />
          <Route path="next-visits" element={<NextVisits />} />
          <Route path="prescriber-profile" element={<PrescriberProfile />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/diagnosis-prediction" element={<Prediction />} />
        <Route path="/medic-prediction" element={<MedicPredictions />} />
      </Routes>
    </>
  );
}

export default App;

import Typography from "@mui/material/Typography";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import styled from "@emotion/styled";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { callPatientTimeLineEndPoint } from "../../utils/endPoints";
import { useParams } from "react-router-dom";
import { Dna } from "react-loader-spinner";
import { Box } from "@mui/material";
import moment from "moment";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";

const TimelineStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  marginTop: 2,
  padding: "6px 10px",
  gap: 6,
  borderRadius: 5,
  "& .MuiTimeline-root": {},
  "& .textnotes ": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: 10,
    borderRadius: 5,
    boxShadow: "0 0 1px 1px rgba(0,0,0,0.1)",
    padding: 20,
    paddingTop: 15,
    "& .MuiTypography-root": {},
    "& span": {
      width: 100,
    },
  },
  "& .MuiTimelineOppositeContent-root": {
    width: 200,
  },
  "& .MuiTimelineContent-root": {
    width: 400,
    flexBasis: 400,
  },
  "&  .MuiTimelineConnector-root": {
    width: 5,
  },
  "& .MuiBox-root:nth-child(even) .MuiTimelineConnector-root": {
    background: "#E51414",
  },
  "& .MuiBox-root:nth-child(odd) .MuiTimelineConnector-root": {
    background: "#464EA0",
  },
}));
export default function PatientTimeline() {
  const param: any = useParams();
  const params = new URLSearchParams({
    pid: param?.pid,
  });

  const { data, isLoading, isError, error }: any = useQuery({
    queryKey: ["timeline", param?.pid],
    queryFn: () => callPatientTimeLineEndPoint(params),
    staleTime: 10000,
    placeholderData: keepPreviousData,
  });

  if (isLoading) {
    return (
      <Dna
        visible={true}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
    );
  }

  if (isError) {
    return (
      <Typography color="error" variant="h6">
        {error?.message}
      </Typography>
    );
  }

  return (
    <>
      {data?.response?.map((res: any, index: any) => (
        <TimelineStyle key={index}>
          <Box style={{ display: "flex", width: "100%", paddingLeft: 100 }}>
            <Typography color={"primary"} variant="subtitle1">
              {res?.year}
              <Typography
                variant="subtitle2"
                component={"span"}
                color={"GrayText"}
              >
                {" "}
                ({res?.appointments?.length} Visits){" "}
              </Typography>
            </Typography>
          </Box>
          <Timeline>
            {res?.appointments?.map((each: any, index: any) => (
              <Box key={index} style={{}}>
                <TimelineItem style={{ minHeight: 20 }}>
                  <TimelineOppositeContent></TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent></TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent></TimelineOppositeContent>
                  <TimelineSeparator>
                    {/* Dot and Color */}

                    <TextSnippetOutlinedIcon
                      style={{ color: "#000", margin: 5 }}
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography
                      variant="body2"
                      color={"GrayText"}
                      component="div"
                      marginBottom={1}
                      marginTop={1}
                      style={{ position: "relative", top: -5 }}
                    >
                      {each?.apptdate} |{" "}
                      {moment(each?.appttime, "HH:mm:ss").format("hh:mm A")}
                    </Typography>
                    <Box className="textnotes">
                      <Typography>
                        {" "}
                        <Typography color={"GrayText"} variant="caption">
                          {" "}
                          Consulted:{" "}
                        </Typography>{" "}
                        <Typography color={"primary"} variant="body1">
                          {" "}
                          Dr. {each?.physician_fname}, {each?.physician_lname}{" "}
                        </Typography>
                      </Typography>
                      <Typography>
                        <Typography>
                          {" "}
                          <Typography color={"GrayText"} variant="caption">
                            {" "}
                            Reason of Visit:{" "}
                            <Typography variant="body1" color={"#000"}>
                              {" "}
                              {each?.reason_for_visit
                                ? each?.reason_for_visit
                                : "NA"}
                            </Typography>{" "}
                          </Typography>{" "}
                        </Typography>
                      </Typography>

                      <>
                        <Typography>
                          <Typography color={"GrayText"} variant="caption">
                            Diagnosis:{" "}
                            <Typography variant="body1" color={"#000"}>
                              {each?.medication
                                ?.map((each: any) => each?.diagnosis)
                                .join(", ")}
                            </Typography>
                          </Typography>
                        </Typography>
                        <Typography>
                          {" "}
                          <Typography color={"GrayText"} variant="caption">
                            {" "}
                            Medication:{" "}
                            <Typography variant="body1" color={"#000"}>
                              {" "}
                              {each?.medication
                                ?.map((each: any) => each?.title)
                                .join(", ")}
                            </Typography>{" "}
                          </Typography>{" "}
                        </Typography>
                        <Typography>
                          {" "}
                          <Typography color={"GrayText"} variant="caption">
                            {" "}
                            Lab test:{" "}
                            <Typography variant="body1" color={"#000"}>
                              {" "}
                              xxx
                            </Typography>{" "}
                          </Typography>{" "}
                        </Typography>
                      </>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              </Box>
            ))}
          </Timeline>
        </TimelineStyle>
      ))}
    </>
  );
}

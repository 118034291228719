import { Grid, Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import TimeIcon from "@mui/icons-material/AccessTime";

interface Patient {
  id: number;
  name: string;
  gender: string;
  age: string;
  time: string;
  status: string;
  physician: string;
  warning: string;
  procedure: string;
  bgcolor: string;
}
const FlowboardPatientCard: React.FC<{ patient: Patient }> = ({ patient }) => {
  return (
    <>
      <Box
        style={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Box style={{ display: "flex", gap: 5 }}>
          <Typography variant="subtitle2" color="primary">
            {patient.name}
          </Typography>
          <Typography variant="body1" component="span" color="gray">
            |
          </Typography>
          <Typography variant="body1">{patient.gender}</Typography>
          <Typography variant="body1" component="span" color="gray">
            |
          </Typography>
          <Typography variant="body1">{patient.age}</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <TimeIcon fontSize="small" color="primary" sx={{ width: 18 }} />
          <Typography variant="body1">{patient.time}</Typography>
        </Box>
      </Box>
      <Box
        style={{
          background: `${patient.bgcolor}`,
          padding: 10,
          display: "flex",
          flexDirection: "column",
          gap: 5,
          borderRadius: 10,
        }}
      >
        <Box style={{ display: "flex", gap: 2 }}>
          <Box
            style={{
              minWidth: "40%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption">Status</Typography>
            <Typography variant="body1">{patient.status}</Typography>
          </Box>
          <Box
            style={{
              minWidth: "30%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption">Physician</Typography>
            <Typography variant="body1">{patient.physician}</Typography>
          </Box>
          <Box
            style={{
              minWidth: "30%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption">Warning</Typography>
            <Typography variant="body1">{patient.warning}</Typography>
          </Box>
        </Box>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">Procedure</Typography>
          <Typography variant="body1">{patient.procedure}</Typography>
        </Box>
      </Box>
    </>
  );
};
export default function FlowBoard() {
  const patientData: Patient[] = [
    {
      id: 1,
      name: "Darla Keira",
      gender: "M",
      age: "30Yrs",
      time: "11:00 AM",
      status: "Insurgery",
      physician: "Smith",
      warning: "Diabetic",
      procedure: "Repair of Nasal Septum",
      bgcolor: "#E4F6D7",
    },
    {
      id: 2,
      name: "Wazinski John",
      gender: "M",
      age: "40Yrs",
      time: "02:30 PM",
      status: "PACU",
      physician: "Edmund",
      warning: "None",
      procedure: "Tonsillectomy",
      bgcolor: "#C3F0ED",
    },
    {
      id: 3,
      name: "Williams Dana",
      gender: "M",
      age: "30Yrs",
      time: "01:00 PM",
      status: "Arrived",
      physician: "Gary",
      warning: "Latex",
      procedure: "Diagnostic Laryngoscopy",
      bgcolor: "#F3F4C3",
    },
    {
      id: 4,
      name: "Adrian Dominic",
      gender: "M",
      age: "30Yrs",
      time: "04:00 PM",
      status: "In Procedure Room",
      physician: "Janes",
      warning: "Latex",
      procedure: "Colonoscopy and Biopsy",
      bgcolor: "#CCE3F3",
    },
    {
      id: 5,
      name: "Carson Finley",
      gender: "M",
      age: "30Yrs",
      time: "05:00 PM",
      status: "In Procedure Room",
      physician: "Gary",
      warning: "PreOp",
      procedure: "Myringoplasty, Left Ear",
      bgcolor: "#CCE3F3",
    },
    {
      id: 6,
      name: "Darla Keira",
      gender: "M",
      age: "30Yrs",
      time: "11:00 AM",
      status: "Insurgery",
      physician: "Smith",
      warning: "Diabetic",
      procedure: "Repair of Nasal Septum",
      bgcolor: "#E4F6D7",
    },
    {
      id: 7,
      name: "Wazinski John",
      gender: "M",
      age: "40Yrs",
      time: "02:30 PM",
      status: "PACU",
      physician: "Edmund",
      warning: "None",
      procedure: "Tonsillectomy",
      bgcolor: "#C3F0ED",
    },
    {
      id: 8,
      name: "Williams Dana",
      gender: "M",
      age: "30Yrs",
      time: "01:00 PM",
      status: "Arrived",
      physician: "Gary",
      warning: "Latex",
      procedure: "Diagnostic Laryngoscopy",
      bgcolor: "#F3F4C3",
    },
    {
      id: 9,
      name: "Adrian Dominic",
      gender: "M",
      age: "30Yrs",
      time: "04:00 PM",
      status: "In Procedure Room",
      physician: "Janes",
      warning: "Latex",
      procedure: "Colonoscopy and Biopsy",
      bgcolor: "#CCE3F3",
    },
    {
      id: 10,
      name: "Carson Finley",
      gender: "M",
      age: "30Yrs",
      time: "05:00 PM",
      status: "In Procedure Room",
      physician: "Gary",
      warning: "PreOp",
      procedure: "Myringoplasty, Left Ear",
      bgcolor: "#CCE3F3",
    },
  ];
  return (
    <>
      <Box padding={{ xs: 3, md: 5 }}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
        >
          <Grid xs={12}>
            <Typography marginBottom={2}>
              <Typography
                variant="subtitle1"
                color={"primary"}
                component={"span"}
              >
                Flow Board
              </Typography>{" "}
              <Typography variant="caption" component={"span"}>
                Patient Current Status{" "}
              </Typography>{" "}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Card>
              <CardContent>
                <Grid
                  container
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  spacing={2}
                >
                  {patientData.map((patient) => (
                    <Grid item xs={12} md={6}>
                      <FlowboardPatientCard
                        key={patient.id}
                        patient={patient}
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

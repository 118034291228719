// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils

// components
import { theme } from "../../theme";

// ----------------------------------------------------------------------

export const AppWidgetSummaryStyle = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.5s ease-out",
  "&:hover ": {
    transform: "scale(1.01)",
    boxShadow: "0 0 10px 2px #48abe066",
  },
  gap: 2,
  boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)",
  textAlign: "left",
  color: theme.palette.primary.dark,
  borderRadius: 20,
  padding: 20,
  justifyContent: "flex-start",
  alignItems: "flex-start",
  [theme.breakpoints.down("md")]: {
    position: "relative",
  },
}));
export const StyledIcon = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  "&.gray": {
    backgroundImage: `linear-gradient(135deg, ${alpha(
      theme.palette.primary.dark,
      0.05
    )} 0%, ${alpha(theme.palette.primary.dark, 0.15)} 100%)`,
    color: theme.palette.primary.dark,
    marginTop: 15,
    marginBottom: 15,
  },
  "&.blue": {
    backgroundImage: `linear-gradient(135deg, ${alpha(
      theme.palette.primary.main,
      0.25
    )} 0%, ${alpha(theme.palette.primary.light, 1)} 100%)`,
    margin: "auto",
    marginBottom: 5,
  },
}));
// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({
  title,
  onClick,
  total,
  text,
  icon,
  color = "primary",
  sx,
  ...other
}: any) {
  return (
    <AppWidgetSummaryStyle onClick={onClick} {...other}>
      <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
        {title}
      </Typography>
      <StyledIcon className="gray">{icon}</StyledIcon>
      <Typography variant="h3">{total}</Typography>
      <Typography variant="body2" sx={{ opacity: 0.72 }}>
        {text}
      </Typography>
    </AppWidgetSummaryStyle>
  );
}

import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ViewIcon from "@mui/icons-material/Visibility";

const columnsRprtDoc = [
  {
    field: "docno",
    headerName: "Doc",
    width: 80,
    cellClassName: "first",
    headerClassName: "first",
  },
  {
    field: "cat",
    headerName: "Category",
    minWidth: 100,
  },
  {
    field: "dsc",
    headerName: "Description",
    minWidth: 250,
    flex: 1,
  },
  {
    field: "option",
    headerName: "",
    width: 80,
    cellClassName: "last",
    headerClassName: "last",
    renderCell: ({}) => (
      <>
        <Typography className="textwrap">
          <IconButton className="tooltip">
            <ViewIcon />
          </IconButton>
        </Typography>
      </>
    ),
  },
];

const rowsRprtDoc = [
  {
    id: 1,
    docno: "1",
    cat: "Appointment",
    dsc: "Nulla fermentum, tellus id blandit dolor, ac efficitur libero libero vitae felis.  ",
  },
  {
    id: 2,
    docno: "2",
    cat: "CCD",
    dsc: "Aliquam vel ligula non vestibulum. In hac habitasse platea dictumst.  ",
  },
];

export default function ReportsDocuments() {
  return (
    <>
      <Card>
        <CardHeader title="Reports/Documents" />
        <CardContent className="h220">
          <div style={{ height: 180, width: "100%" }}>
            <DataGrid
              rows={rowsRprtDoc}
              columns={columnsRprtDoc}
              hideFooter
              autoPageSize
              rowHeight={30}
              columnHeaderHeight={20}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
}

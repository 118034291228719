import { Grid } from "@mui/material";
import MyReports from "../../../../components/MyReports";
import MyMedications from "../../../../components/MyMedications";

export default function HistoryPanel() {
  return (
    <>
      <Grid
        container
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={3}
        height={"100vh"}
      >
        <Grid item xs={12} md={6}>
          <MyReports />
        </Grid>
        <Grid item xs={12} md={6}>
          <MyMedications />
        </Grid>
      </Grid>
    </>
  );
}

import { useQuery } from "@tanstack/react-query";
import { callTestsPredict } from "../utils/endPoints";

export const useTests = (queryString: string) => {
  const bodyData = {
    disease_id: queryString,
  };
  return useQuery({
    queryKey: ["tests", queryString],
    queryFn: () => callTestsPredict(bodyData),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !!queryString,
  });
};

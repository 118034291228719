import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useRouteMatch } from "./AppointmentHistory";

export default function Appointments() {
  const [selectedTab, setSelectedTab] = useState("upcoming");

  const handleChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const routeMatch = useRouteMatch([
    "/upcoming",
    "/completed",
    "/open-slots",
    "/recalls",
  ]);
  const currentTab: any = routeMatch?.pattern?.path;

  useEffect(() => {
    if (currentTab === "/upcoming") {
      setSelectedTab("upcoming");
    } else if (currentTab === "/completed") {
      setSelectedTab("completed");
    } else if (currentTab === "/open-slots") {
      setSelectedTab("open slots");
    } else {
      setSelectedTab("recalls");
    }
  }, [currentTab]);
  return (
    <>
      <TabContext value={selectedTab}>
        <TabList
          sx={{ padding: "20px 0px 0 5px", height: "auto", borderRadius: 0 }}
          onChange={handleChange}
          variant="scrollable"
        >
          <Tab
            component={Link}
            to="upcoming"
            key={"1"}
            label={"upcoming"}
            value={"upcoming"}
          />
          <Tab
            component={Link}
            to="completed"
            key={"2"}
            label={"completed"}
            value={"completed"}
          />
          <Tab
            component={Link}
            to="open-slots"
            key={"3"}
            label={"open slots"}
            value={"open slots"}
          />
          <Tab
            component={Link}
            to="recalls"
            key={"4"}
            label={"recalls"}
            value={"recalls"}
          />
        </TabList>

        <TabPanel className="apts" value={"upcoming"}>
          <Outlet />
        </TabPanel>
        <TabPanel className="apts" value={"completed"}>
          <Outlet />
        </TabPanel>
        <TabPanel className="apts" value={"open slots"}>
          <Outlet />
        </TabPanel>
        <TabPanel className="apts" value={"recalls"}>
          <Outlet />
        </TabPanel>
      </TabContext>
    </>
  );
}

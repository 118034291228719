import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import { FrmLbl } from "../../theme";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { callPatientVitals } from "../../utils/endPoints";
import { useParams } from "react-router-dom";

export default function Vitals() {
  const params: any = useParams();
  const { data, isLoading, isError }: any = useQuery({
    queryKey: ["vitals", params],
    queryFn: () => callPatientVitals(params),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });
  return (
    <>
      <Card>
        <CardHeader title="Vitals" />
        {isLoading ? (
          <Box>
            <LinearProgress />
          </Box>
        ) : isError ? (
          <Typography color="error" variant="h6">
            something went wrong!
          </Typography>
        ) : (
          <CardContent className="h150">
            <Grid
              container
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={3}
            >
              <Grid item xs={6}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    Blood Pressure Systolic:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.bps ?? "NA"} {data[0]?.bps_unit}
                  </Typography>
                </FrmLbl>
              </Grid>
              <Grid item xs={6}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    Blood Pressure Diastolic:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.bpd ?? "NA"} {data[0]?.bpd_unit}
                  </Typography>
                </FrmLbl>
              </Grid>
              <Grid item xs={6}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    Weight:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.weight ?? "NA"} {data[0]?.weight_unit}
                  </Typography>
                </FrmLbl>
              </Grid>
              <Grid item xs={6}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    Height:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.height ?? "NA"} {data[0]?.height_unit}
                  </Typography>
                </FrmLbl>
              </Grid>
              <Grid item xs={6}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    BMI:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.BMI ?? "NA"} {data[0]?.BMI_unit}
                  </Typography>
                </FrmLbl>
              </Grid>
              <Grid item xs={6}>
                <FrmLbl>
                  <Typography variant="body2" component={"label"}>
                    Date:
                  </Typography>
                  <Typography variant="body2" component={"div"}>
                    {data[0]?.date ?? "NA"}
                  </Typography>
                </FrmLbl>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </>
  );
}

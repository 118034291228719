import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs, { Dayjs } from "dayjs";
import { styled } from "@mui/material/styles";
export const Openslots = styled("div")(({ theme }) => ({
  "& .MuiTypography-root.MuiFormControlLabel-label": {
    fontSize: 14,
  },
  "&  a  ": {
    color: theme.palette.primary.main,
  },
  "& .MuiTypography-subtitle1": {
    marginBottom: 20,
  },

  "& .MuiStack-root ": {
    padding: 0,
  },
  "& .MuiDateCalendar-root": {
    margin: 0,
    [theme.breakpoints.down("md")]: {
      width: 270,
    },
    "& .MuiDayCalendar-header * ": {
      fontWeight: 600,
    },
    "& .MuiDayCalendar-header , & .MuiDayCalendar-weekContainer ": {
      gap: 6,
      margin: "6px 0",
      [theme.breakpoints.down("md")]: {
        gap: 5,
        margin: "5px 0",
      },
    },
    "& .MuiPickersDay-root  ": {
      background: "#fff",
      border: "1px solid #ddd",
      [theme.breakpoints.down("md")]: {
        width: 30,
        height: 30,
      },
    },
    "& .MuiPickersDay-root.Mui-selected ": {
      background: "#AC0000",
    },
    "& .MuiPickersSlideTransition-root ": {
      minHeight: 300,
      [theme.breakpoints.down("md")]: {
        minHeight: 240,
      },
    },
    '& .MuiPickersDay-root[aria-colindex="7"] ': {
      background: "#D4E2FF",
    },
    "& .MuiPickersCalendarHeader-root ": {
      padding: 0,
      margin: 0,
      position: "relative",
      "& .MuiPickersArrowSwitcher-root ": {
        justifyContent: "space-between",
        width: "100%",
      },
      "& .MuiPickersCalendarHeader-labelContainer ": {
        position: "absolute",
        top: 0,
        transform: "translate(-50%)",
        left: "50%",
        "& .MuiPickersFadeTransitionGroup-root ": {
          width: "max-content",
        },
      },
    },
  },
}));
export const Timeslots = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: 10,
  marginBottom: 20,
  "& .MuiButton-root": {
    borderRadius: 15,
    borderColor: "#567BC2",
    [theme.breakpoints.down("md")]: { minWidth: "inherit" },
    transition: "all 0.5s ease-out",
    "&:hover ": {
      boxShadow: "0 0 10px 2px #48abe066",
    },
    "&.MuiButton-outlined": {
      color: theme.palette.primary.main,
      background: "#fff",
    },
    "&.Mui-disabled": {
      color: "#A1B1C8",
      background: "#E0E9F4",
      border: "1px solid #ACC0DA",
    },
  },
  [theme.breakpoints.down("md")]: {},
}));
export const OSFrmLbl = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: 5,
  "& label.MuiTypography-body2 ": {
    color: "#666",
    fontWeight: 400,
    minWidth: 130,
    width: 130,
    [theme.breakpoints.down("md")]: {
      minWidth: 110,
      width: 110,
    },
  },
  "& .MuiRadio-root ": {
    padding: 5,
  },
  "& .MuiFormGroup-root ": {
    marginTop: -9,
  },
  "& .MuiTypography-body2 ": {
    color: "#000",
  },
  "& .MuiTypography-root.applink , & .MuiTypography-root.applink a ": {
    wordBreak: "break-all",
  },

  "&.ptd ": {
    "& label.MuiTypography-body2 ": {
      minWidth: "inherit",
      width: "auto",
    },
  },
}));
export default function OpenSlots() {
  const [value, setValue] = useState<Dayjs | null>(dayjs());
  const [time, setTime] = useState<string>("");
  const [radioValue, setRadioValue] = useState("UC");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };
  return (
    <>
      <Openslots>
        <Grid
          container
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={1}
        >
          <Grid item xs={12} md={4}>
            <Typography fontWeight={"bold"} variant="subtitle1">
              Choose date
            </Typography>
            <Box className="osdate">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                  <DateCalendar
                    value={value}
                    onChange={(newValue: any) => setValue(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid
              container
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={1}
            >
              <Grid item xs={12} md={2}>
                <Typography variant="body2" component={"label"}>
                  Pick time:
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Timeslots>
                  <Button
                    disabled
                    size="small"
                    variant={time === "1" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("1")}
                  >
                    04:30 PM
                  </Button>
                  <Button
                    disabled
                    size="small"
                    variant={time === "2" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("2")}
                  >
                    06:30 AM
                  </Button>
                  <Button
                    size="small"
                    variant={time === "3" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("3")}
                  >
                    06:30 AM
                  </Button>
                  <Button
                    size="small"
                    variant={time === "4" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("4")}
                  >
                    11:30 AM
                  </Button>

                  <Button
                    size="small"
                    variant={time === "5" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("5")}
                  >
                    12:30 PM
                  </Button>
                  <Button
                    size="small"
                    type="button"
                    variant={time === "6" ? "contained" : "outlined"}
                    onClick={() => setTime("6")}
                  >
                    04:30 PM
                  </Button>
                  <Button
                    size="small"
                    variant={time === "7" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("7")}
                  >
                    08:00 PM
                  </Button>
                  <Button
                    size="small"
                    variant={time === "8" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("8")}
                  >
                    10:15 AM
                  </Button>
                  <Button
                    size="small"
                    variant={time === "9" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("9")}
                  >
                    10:54 PM
                  </Button>
                  <Button
                    disabled
                    size="small"
                    variant={time === "10" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("10")}
                  >
                    10:15 AM
                  </Button>
                  <Button
                    size="small"
                    variant={time === "11" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("11")}
                  >
                    07:15 AM
                  </Button>
                  <Button
                    disabled
                    size="small"
                    variant={time === "12" ? "contained" : "outlined"}
                    type="button"
                    onClick={() => setTime("12")}
                  >
                    10:35 AM
                  </Button>
                </Timeslots>
              </Grid>
            </Grid>
            {time && (
              <>
                <Grid
                  container
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  spacing={1}
                >
                  <Grid item xs={4} md={2}>
                    <Typography variant="body2" component={"div"}>
                      Slot Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={10}>
                    <Typography variant="body2">
                      {value?.format("DD-MM-YYYY")}
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Typography variant="body2" component={"div"}>
                      Slot Time:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={10}>
                    <Typography variant="body2">2:30 - 3:30</Typography>
                  </Grid>

                  <Grid item xs={4} md={2}>
                    <Typography variant="body2" component={"div"}>
                      Select Category:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={10}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={radioValue}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value="UC"
                        control={<Radio />}
                        label="Urgent care"
                      />
                      <FormControlLabel
                        value="PC"
                        control={<Radio />}
                        label="Primary Care"
                      />
                      <FormControlLabel
                        value="SP"
                        control={<Radio />}
                        label="Specialty"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Typography variant="body2" component={"div"}>
                      Appointment Types:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={10}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={radioValue}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value="UC"
                        control={<Radio />}
                        label="Behavioral"
                      />
                      <FormControlLabel
                        value="PC"
                        control={<Radio />}
                        label="Non-Behavioral"
                      />
                      <FormControlLabel
                        value="SP"
                        control={<Radio />}
                        label="General consultation"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Typography variant="body2" component={"div"}>
                      Appointment Links:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={10}>
                    <Typography variant="body2" className="applink">
                      <a
                        style={{ wordBreak: "break-word" }}
                        href="https://meet.google.com/syb-wbyr-pow?authuser=0&hs=122&ijlm=1687944356439"
                      >
                        https://meet.google.com/syb-wbyr-pow?authuser=0&hs=122&ijlm=1687944356439
                      </a>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Typography variant="body2" component={"div"}>
                      Patient Details:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={10}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body2">Ramesh Kumar</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <OSFrmLbl className="ptd">
                          {" "}
                          <Typography variant="body2" component={"label"}>
                            Gender:
                          </Typography>
                          <Typography variant="body2">Male</Typography>
                        </OSFrmLbl>
                      </Grid>
                      <Grid item xs={12}>
                        <OSFrmLbl className="ptd">
                          <Typography variant="body2" component={"label"}>
                            DOB:
                          </Typography>
                          <Typography variant="body2">09/09/1990</Typography>{" "}
                        </OSFrmLbl>
                      </Grid>
                      <Grid item xs={12}>
                        <OSFrmLbl className="ptd">
                          {" "}
                          <Typography variant="body2" component={"label"}>
                            Phone:
                          </Typography>
                          <Typography variant="body2">1324512345</Typography>{" "}
                        </OSFrmLbl>
                      </Grid>
                      <Grid item xs={12}>
                        <OSFrmLbl className="ptd">
                          {" "}
                          <Typography variant="body2" component={"label"}>
                            Email:
                          </Typography>
                          <Typography variant="body2" className="applink">
                            <a href="mailto:nathanschmitt12@gmail.com">
                              nathanschmitt12@gmail.com
                            </a>
                          </Typography>
                        </OSFrmLbl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} md={2}></Grid>
                  <Grid item xs={8} md={10}>
                    {" "}
                    <Button variant="contained" size="large">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Openslots>
    </>
  );
}

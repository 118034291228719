import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Switch,
} from "@mui/material";
import Carousel from "react-bootstrap/Carousel";
import { GraphStyle } from "../../theme";
import bpGraph from "../../assets/images/bp-graph.svg";
import weightGraph from "../../assets/images/weight-graph.svg";
import heightGraph from "../../assets/images/height-graph.svg";
import bmiGraph from "../../assets/images/bmi-graph.svg";

export default function VitalsAnthropometric() {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      <Card>
        <CardHeader
          title="Vitals & Anthropometric"
          action={
            <>
              {" "}
              <Typography
                variant="caption"
                component={"div"}
                textAlign={"right"}
              >
                Recorded by Officer <Switch {...label} />
              </Typography>
            </>
          }
        />
        <CardContent>
          <Carousel data-bs-theme="dark">
            <Carousel.Item>
              {" "}
              <Carousel.Caption>
                <Typography
                  color={"secondary"}
                  variant="body1"
                  marginBottom={1}
                  component={"div"}
                  className="body1"
                >
                  Blood Pressure
                </Typography>
              </Carousel.Caption>
              <GraphStyle>
                {" "}
                <img src={bpGraph} className="w-100" alt="BP" />
              </GraphStyle>
            </Carousel.Item>
            <Carousel.Item>
              {" "}
              <Carousel.Caption>
                <Typography
                  color={"secondary"}
                  variant="body1"
                  marginBottom={1}
                  component={"div"}
                  className="body1"
                >
                  BMI
                </Typography>
              </Carousel.Caption>
              <GraphStyle>
                {" "}
                <img src={bmiGraph} className="w-100" alt="BMI" />{" "}
              </GraphStyle>
            </Carousel.Item>
            <Carousel.Item>
              {" "}
              <Carousel.Caption>
                <Typography
                  color={"secondary"}
                  variant="body1"
                  marginBottom={1}
                  component={"div"}
                  className="body1"
                >
                  Weight
                </Typography>
              </Carousel.Caption>
              <GraphStyle>
                {" "}
                <img src={weightGraph} className="w-100" alt="WT" />{" "}
              </GraphStyle>
            </Carousel.Item>
            <Carousel.Item>
              {" "}
              <Carousel.Caption>
                <Typography
                  color={"secondary"}
                  variant="body1"
                  marginBottom={1}
                  component={"div"}
                  className="body1"
                >
                  Height
                </Typography>
              </Carousel.Caption>
              <GraphStyle>
                {" "}
                <img src={heightGraph} className="w-100" alt="HT" />{" "}
              </GraphStyle>
            </Carousel.Item>
          </Carousel>
        </CardContent>
      </Card>
    </>
  );
}

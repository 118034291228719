import { ArrowBack } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Grid
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Grid item xs={12}>
        {" "}
        <Typography
          fontWeight={"bold"}
          textAlign={"center"}
          variant={"h1"}
          color={"primary"}
          marginBottom={2}
        >
          Oops!
        </Typography>
        <Typography
          fontWeight={"bold"}
          textAlign={"center"}
          variant={"h2"}
          color={"primary"}
          marginBottom={2}
        >
          404 - Page not Found!
        </Typography>
        <Typography color="secondary" component="div" variant="subtitle1">
          The page you are looking for might have been removed had its name{" "}
          <br />
          changed or is temporarily unavailable.
        </Typography>
      </Grid>

      <Grid item xs={12} my={2}>
        <Button
          startIcon={<ArrowBack />}
          className=""
          children={"Go Back"}
          variant={"contained"}
          size={"large"}
          type={"button"}
          onClick={() => {
            navigate(-1);
          }}
        />
      </Grid>
    </Grid>
  );
}

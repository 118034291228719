import { Box, Grid, Typography, styled } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React, { useCallback, useState } from "react";
import PTSWidget from "../../../components/PTSWidget";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { callAppointments } from "../../../utils/endPoints";
import moment from "moment";
import DnaLoading from "../../../components/DnaLoading";
import Alert from "@mui/material/Alert";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
export const PTSCardListStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
}));
export const PTSWidgetStyle = styled("div")(({ theme }) => ({
  transition: "all 0.5s ease-out",
  width: "100%",
  minWidth: 160,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: 10,
  padding: 0,
}));

export default function Upcoming() {
  const navigate = useNavigate();
  const [DWMState, setDWMState] = useState<string>("D");

  const filteredData = useCallback(
    (data: any) => {
      if (data) {
        return data.filter(
          ({ pc_eventDate, pc_startTime, pc_endTime }: any) => {
            const currentDate = moment();
            const itemDate = moment(`${pc_eventDate} ${pc_startTime}`);
            const completedTime = moment(`${pc_eventDate} ${pc_endTime}`);
            if (completedTime.isBefore(currentDate)) {
              return false;
            }
            if (DWMState === "D") {
              return itemDate.isSame(currentDate, "day");
            } else if (DWMState === "M") {
              const upcomingDate = moment().add(1, "month");
              return itemDate.isBetween(currentDate, upcomingDate);
            } else if (DWMState === "W") {
              const upcomingDate = moment().add(1, "week");
              return itemDate.isBetween(currentDate, upcomingDate);
            }

            return true;
          }
        );
      }
      return [];
    },
    [DWMState]
  );

  const { isError, isLoading, data, failureReason }: any = useQuery({
    queryKey: ["appointments"],
    queryFn: () => callAppointments(),
    select: filteredData,
  });

  if (isLoading) {
    return <DnaLoading />;
  }

  if (isError) {
    return <h1>{failureReason?.message ?? ""}</h1>;
  }

  return (
    <>
      <Grid
        container
        item
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={3}
      >
        <Grid item xs={12} md={6}>
          <Box
            style={{
              display: "flex",
              gap: 2,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {/* <Tooltip title="Prev Date">
              <IconButton>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip> */}
            <Typography className="today" variant="subtitle1">
              {moment().format("MMMM Do YYYY")}
            </Typography>
            {/* <Tooltip title="Next Date">
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </Tooltip> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonGroup
            className="DWM"
            variant="outlined"
            aria-label="outlined button group"
          >
            <Button
              onClick={() => setDWMState("D")}
              className={DWMState === "D" ? "active" : ""}
            >
              D
            </Button>
            <Button
              onClick={() => setDWMState("W")}
              className={DWMState === "W" ? "active" : ""}
            >
              W
            </Button>
            <Button
              onClick={() => setDWMState("M")}
              className={DWMState === "M" ? "active" : ""}
            >
              M
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <PTSWidgetStyle>
            <Grid
              container
              justifyContent={"flex-start"}
              alignItems={"center"}
              spacing={2}
            >
              {data.length > 0 ? (
                data.map((eachItem: any) => (
                  <Grid key={eachItem?.pc_eid} item xs={12} sm={6} md={4}>
                    <PTSWidget
                      key={eachItem?.pc_eid}
                      onClick={() =>
                        navigate(
                          `${eachItem?.pc_eid}/${eachItem?.pid}/${eachItem?.puuid}/history`
                        )
                      }
                      name={eachItem?.fname + " " + eachItem?.lname}
                      time={eachItem?.pc_eventDate}
                      gender={eachItem?.sex ?? "NA"}
                      age={
                        moment().diff(eachItem?.DOB, "years").toString() +
                        " Yrs"
                      }
                      startTime={eachItem?.pc_startTime}
                      endTime={eachItem?.pc_endTime}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Alert variant="outlined" severity="info">
                    <Typography component={"div"} variant="subtitle2">
                      No Results
                    </Typography>
                    <Typography component={"div"} variant="caption">
                      Sorry there are no results for this filters
                    </Typography>
                  </Alert>
                </Grid>
              )}
            </Grid>
          </PTSWidgetStyle>
        </Grid>
      </Grid>
    </>
  );
}

// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Avatar, Box, Card, Typography } from "@mui/material";
// utils

// components
import { theme } from "../../theme";
import moment from "moment";
import { ArrowForwardIos } from "@mui/icons-material";

// ----------------------------------------------------------------------

export const PatientCardStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  minWidth: 160,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: 20,
  padding: 20,
  gap: 2,
  boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)",
  border: "1px solid #A3BBEB",
  background: "#fff",
}));

// ----------------------------------------------------------------------

PatientCard.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  id: PropTypes.number,
  fname: PropTypes.string,
  lname: PropTypes.string,
  sex: PropTypes.string,
  age: PropTypes.string,
  date: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string,
  text: PropTypes.string,
  sx: PropTypes.object,
};

export default function PatientCard({
  title,
  onClick,
  text,
  icon,
  color = "primary",
  sx,
  id,
  fname,
  lname,
  sex,
  age,
  date,
  ...other
}: any) {
  return (
    <>
      <PatientCardStyle>
        <Avatar sx={{ bgcolor: `#44${id}CBA` }}>
          {fname[0]}
          {lname[0]}
        </Avatar>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" color="primary">
            {title} {fname} {lname}
          </Typography>
          <Typography variant="caption" color="secondary">
            {sex} | {age}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption" color="secondary">
            Last visit
          </Typography>
          <Typography variant="caption" color="primary">
            {moment(date).format("MM/DD/YYYY")}
          </Typography>
        </Box>
        <ArrowForwardIos />
      </PatientCardStyle>
    </>
  );
}

import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { callPatientPredictSummary } from "../utils/endPoints";

export const useSummary = (data: any, enableUntil: any) => {
  return useQuery({
    queryKey: ["summary", data],
    queryFn: () => callPatientPredictSummary(data),
    staleTime: 10000,
    enabled: !!enableUntil,
    placeholderData: keepPreviousData,
  });
};

import { Box, Grid, Paper } from "@mui/material";
import Cookies from "js-cookie";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import YSAppLogo from "../../assets/images/ysapp-logo.svg";
import SigninBg from "../../assets/images/signin-bg.jpg";
import { theme } from "../../theme";
import Copyright from "../../components/Copyright";
export default function AuthLayout({ children }: any) {
  const TOKEN = Cookies.get("JWT_TOKEN");
  const DIAGNOSIS_TOKEN = Cookies.get("DIAGNOSIS_TOKEN");

  if (TOKEN !== undefined && DIAGNOSIS_TOKEN !== undefined) {
    return <Navigate to="/app/home" />;
  } else {
    return (
      <>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid
            item
            xs={false}
            sm={5}
            md={7}
            sx={{
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
              backgroundImage: `url("${SigninBg}")`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"row"}
          >
            {" "}
            <Grid
              height={150}
              width={150}
              borderRadius={50}
              bgcolor={"#1A4176"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              margin={2}
            >
              <img src={YSAppLogo} alt="YS App Logo" loading="lazy" />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7} md={5}>
            <Box
              sx={{
                mx: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Outlet /> <Copyright />{" "}
              </div>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

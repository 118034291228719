import { Navigate, Outlet, useNavigate } from "react-router-dom";
import YSLogo from "../../assets/images/YoungsoftIndiaLogo.png";
import * as React from "react";
import { styled, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { theme } from "../../theme";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import YSIcon from "../../assets/images/ys-icon.png";
import Cookies from "js-cookie";
import MainListItems from "./listitems";
import RootCopyRight from "../../components/RootCopyRight";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useQueryClient } from "@tanstack/react-query";
import { AccountIcon, SingOutIcon } from "../../assets/icons";

export const LogoStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .logo": {
    maxHeight: 40,
    margin: 10,
  },
  [defaultTheme.breakpoints.down("md")]: { display: "none" },
}));

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: "linear-gradient(181deg, #1A4176 0%,  #092852 50%, #1A4176 100%)",
  [theme.breakpoints.down("md")]: {
    boxShadow: "none",
  },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    [theme.breakpoints.up("md")]: {
      // marginLeft: drawerWidth,
      //   width: `calc(100% - ${drawerWidth}px)`,
      width: `100%`,
    },
    [theme.breakpoints.down("md")]: {
      //  display:'none',
      boxShadow: "none",
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    border: "none",
    [theme.breakpoints.down("md")]: {
      width: "0 !important",
    },
    background: "linear-gradient(275deg, #ffffff 0%, #819ec51a 100%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function RootLayout() {
  const query = useQueryClient();
  const TOKEN = Cookies.get("JWT_TOKEN");
  const DIAGNOSIS_TOKEN = Cookies.get("DIAGNOSIS_TOKEN");
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{ marginTop: 30 }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => navigate("/app/prescriber-profile")}>
        <ListItemIcon>
          <AccountIcon />
        </ListItemIcon>
        <ListItemText>Account</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/login");
          Cookies.remove("JWT_TOKEN");
          Cookies.remove("Medic_Token");
          Cookies.remove("DIAGNOSIS_TOKEN");
          localStorage.clear();
          query.clear();
        }}
      >
        <ListItemIcon>
          <SingOutIcon />
        </ListItemIcon>
        <ListItemText>Sign Out</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Box sx={{ display: "flex", position: "relative" }}>
        <CssBaseline />

        <AppBar position="fixed" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                //  ...(open && { display: "none" }),
                display: { xs: "none", md: "flex" },
              }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              sx={{ display: { xs: "flex", md: "none" }, color: "#fff" }}
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{
                gap: 1,
                flexGrow: 1,
                margin: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={YSIcon}
                className="ysicon"
                height={30}
                alt="YSI-Icon"
                loading="lazy"
              />{" "}
              Provider App
            </Typography>
            <Box>
              {/* <IconButton
                component={NavLink}
                to="/notifications"
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMenu}
        <Drawer variant="permanent" open={open}>
          {/* <LogoStyle>
            <img className="logo" src={YSLogo} alt="Youngsoft Inc.," />
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
          </LogoStyle> */}
          <Box marginTop={7}></Box>
          <Divider />
          <List component="nav" className="navmenu">
            <MainListItems />
          </List>
        </Drawer>
        <Box
          component="main"
          height={"100%"}
          sx={{
            // backgroundColor: (theme) =>
            //   theme.palette.mode === 'light'
            //     ? theme.palette.grey[100]
            //     : theme.palette.grey[900],
            flexGrow: 1,
            // height: "90vh",
            // overflow: "auto",
            [theme.breakpoints.down("sm")]: {
              //   background: theme.palette.primary.main,
              //    overflow: "hidden",
            },
          }}
        >
          <Toolbar className="topspacebar" />
          {TOKEN !== undefined && DIAGNOSIS_TOKEN !== undefined ? (
            <Outlet />
          ) : (
            <Navigate to="/login" />
          )}
          <Toolbar className="bottomspacebar" />
        </Box>
      </Box>
      <RootCopyRight />
    </>
  );
}

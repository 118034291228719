import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";
import { useState } from "react";
const ClinicalNotesMenuStyle = styled("div")(({ theme }) => ({
  border: "1px solid #ccc",
  borderRadius: 5,
  overflow: "hidden",
  padding: 0,
  "&  .MuiList-root  ": {
    padding: 0,
  },
  "& .MuiButtonBase-root": {
    border: "none",
    borderTop: "1px solid #ccc",
    padding: 3,
    margin: 0,
    color: "#000",
    "& .MuiTypography-root": {
      fontSize: 13,
    },
  },
  "& .MuiListItemIcon-root": {
    minWidth: 22,
    "&  svg": {
      width: 16,
    },
  },
  "& .MuiCollapse-wrapper   ": {
    padding: 5,
    "&  .MuiList-root  ": {
      padding: 2,
      "& .MuiListItemText-root": {
        margin: 0,
      },
      "&  .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 10,
        border: "none",
        color: "#666",
        background: "none",
        "& .MuiTypography-root": {
          fontSize: 12,
        },
      },
      "& .MuiListItemIcon-root": {
        minWidth: 10,
        "&  svg": {
          width: 4,
          height: "auto",
        },
      },
    },
  },
}));
export default function ClinicalNotesMenu({
  setClinicNotes,
  patientData,
}: any) {
  const [opencp, setOpenCP] = useState(false);
  const [openpd, setOpenPD] = useState(false);
  const [openmp, setOpenMP] = useState(false);
  const [openm, setOpenM] = useState(false);
  const [openmds, setOpenMDs] = useState(false);
  const [opens, setOpenS] = useState(false);
  const handleClickCP = () => {
    setOpenCP(!opencp);
  };
  const handleClickPD = () => {
    setOpenPD(!openpd);
  };
  const handleClickMP = () => {
    setOpenMP(!openmp);
  };
  const handleClickM = () => {
    setOpenM(!openm);
  };
  const handleClickMDs = () => {
    setOpenMDs(!openmds);
  };
  const handleClickS = () => {
    setOpenS(!opens);
  };
  return (
    <ClinicalNotesMenuStyle>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={handleClickCP}>
          <ListItemIcon>
            {opencp ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText primary="Components" />
        </ListItemButton>
        <Collapse in={opencp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="Test Component"
                onClick={() =>
                  setClinicNotes((prev: any) => prev + "test component")
                }
              />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton onClick={handleClickPD}>
          <ListItemIcon>
            {openpd ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText primary="Patient Details" />
        </ListItemButton>
        <Collapse in={openpd} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="First Name"
                onClick={() =>
                  setClinicNotes((prev: any) => prev + patientData?.fname)
                }
              />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="Last Name"
                onClick={() =>
                  setClinicNotes((prev: any) => prev + patientData?.lname)
                }
              />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="Phone"
                onClick={() =>
                  setClinicNotes((prev: any) => prev + patientData?.phone_cell)
                }
              />
            </ListItemButton>
          </List>{" "}
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="Date of Birth"
                onClick={() =>
                  setClinicNotes((prev: any) => prev + patientData?.DOB)
                }
              />
            </ListItemButton>
          </List>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="PCP"
                onClick={() => setClinicNotes((prev: any) => prev + "****")}
              />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton onClick={handleClickMP}>
          <ListItemIcon>
            {openmp ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText primary="Medical Problems" />
        </ListItemButton>
        <Collapse in={openmp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="Asthma"
                onClick={() => setClinicNotes((prev: any) => prev + "asthma")}
              />
            </ListItemButton>
          </List>{" "}
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="Cancer"
                onClick={() => setClinicNotes((prev: any) => prev + "cancer")}
              />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton onClick={handleClickM}>
          <ListItemIcon>{openm ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
          <ListItemText primary="Medications" />
        </ListItemButton>
        <Collapse in={openm} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="Lipitor"
                onClick={() => setClinicNotes((prev: any) => prev + "lipitor")}
              />
            </ListItemButton>
          </List>{" "}
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="Metformin"
                onClick={() =>
                  setClinicNotes((prev: any) => prev + "Metformin")
                }
              />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton onClick={handleClickMDs}>
          <ListItemIcon>
            {openmds ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText primary="Medical Devices" />
        </ListItemButton>
        <Collapse in={openmds} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>{" "}
              <ListItemText
                primary="Test Medical Issue"
                onClick={() =>
                  setClinicNotes((prev: any) => prev + "Test Medical Issue")
                }
              />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton onClick={handleClickS}>
          <ListItemIcon>{opens ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
          <ListItemText primary="Surgeries" />
        </ListItemButton>
        <Collapse in={opens} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText
                primary="ALT OD"
                onClick={() => setClinicNotes((prev: any) => prev + "alt do")}
              />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </ClinicalNotesMenuStyle>
  );
}

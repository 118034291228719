import { Typography, Card, CardHeader, CardContent } from "@mui/material";

export default function ReasonforVisit({ reason }: any) {
  return (
    <>
      <Card>
        <CardHeader title="Reason for Visit" />
        <CardContent>
          <Typography color={"primary"} variant="body2" component={"div"}>
            {reason ?? "Not Available"}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

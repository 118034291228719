import { useQuery } from "@tanstack/react-query";
import { callMedicationPredict } from "../utils/endPoints";

export const useMedications = (queryString: string) => {
  const bodyData = {
    disease_id: queryString,
  };
  return useQuery({
    queryKey: ["medications", queryString],
    queryFn: () => callMedicationPredict(bodyData),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !!queryString,
  });
};

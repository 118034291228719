import React, { useState } from "react";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import YSAppLogo from "../../assets/images/ysapp-logo.svg";
import { useNavigate } from "react-router-dom";
import {
  EmailIcon,
  HidepwdIcon,
  LockIcon,
  ShowpwdIcon,
} from "../../assets/icons";
import Cookies from "js-cookie";
import {
  callDiagnosisToken,
  callLoginApiEndPoint,
} from "../../utils/endPoints";
import SnackBar from "../../components/SnackBar";
import { AlertColor } from "@mui/material";
import { Dna } from "react-loader-spinner";
import Avatar from "@mui/material/Avatar";
import { theme } from "../../theme";
import axios from "axios";
interface Object {
  username: string;
  password: string;
}

export interface AlertType {
  message: string;
  open: boolean;
  variant: AlertColor;
}

export default function SignIn() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [alertState, setAlertState] = useState<AlertType>({
    message: "",
    open: false,
    variant: "warning",
  });
  const [data, setData] = useState<Object>({
    username: "",
    password: "",
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const getApiMedicToken = async () => {
    // const credentials = `Cn5k4S3Aio7M6Erq2`;
    // const encodedCode = Buffer.from(credentials).toString("base64");
    try {
      const api = await axios.post(
        "https://authservice.priaid.ch/login",
        {},
        {
          headers: {
            Authorization: `Bearer m9YAe_YOUNGSOFT_IN_AUT:7SBbKHy5DIPsEWwJ2i2alw==`,
          },
        }
      );
      if (api.status === 200) {
        Cookies.set("Medic_Token", api?.data?.Token, {
          expires: new Date(new Date().getTime() + 50 * 60 * 1000), // expires in 50 minutes
        });
      }
    } catch (error: any) {
      setAlertState((prevState: any) => {
        return {
          ...prevState,
          message: error?.message,
          variant: "error",
          open: true,
        };
      });
    }
  };

  const getDiagnosisToken = async () => {
    try {
      const bodyData = {
        username: "provider",
        password: "ritwik@123",
      };
      const result = await callDiagnosisToken(bodyData);
      Cookies.set("DIAGNOSIS_TOKEN", result?.access_token, {
        expires: new Date(new Date().getTime() + 28 * 60 * 1000), // expires in 28 minutes
      });
      setLoading(false);
      navigate("/app/home", { replace: true });
    } catch (error: any) {
      setAlertState((prevState: any) => {
        return {
          ...prevState,
          message: error?.message,
          variant: "error",
          open: true,
        };
      });
    }
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      setLoading(true);
      const body = {
        email: data.username,
        password: data.password,
      };
      const result = await callLoginApiEndPoint(body);
      Cookies.set("JWT_TOKEN", result?.token, {
        expires: new Date(new Date().getTime() + 50 * 60 * 1000), // expires in 50 minutes
      });
      localStorage.setItem("providerId", result?.user_info?.data?.uuid);
      localStorage.setItem(
        "providerName",
        `${result?.user_info?.data?.fname} ${result?.user_info?.data?.mname} ${result?.user_info?.data?.lname}`
      );
      getApiMedicToken();
      getDiagnosisToken();
    } catch (error: any) {
      setLoading(false);
      setAlertState((prevState: any) => {
        return {
          ...prevState,
          message: error?.message,
          variant: "error",
          open: true,
        };
      });
    }
  };

  return (
    <>
      <>
        <Avatar
          sx={{
            [theme.breakpoints.up("sm")]: {
              display: "none",
            },
            m: 1,
            width: 100,
            height: 100,
            bgcolor: theme.palette.primary.main,
          }}
        >
          <img src={YSAppLogo} width={100} alt="YS App Logo" loading="lazy" />
        </Avatar>
        <Typography
          color={"primary"}
          component="div"
          variant="h3"
          fontWeight={400}
          marginTop={2}
          marginBottom={2}
        >
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Typography component="div" variant="body2" marginBottom={1}>
            Email Address
          </Typography>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            margin="normal"
            required
            fullWidth
            id="username"
            placeholder="Email Address"
            name="username"
            autoComplete="username"
            autoFocus
            value={data.username}
            onChange={(e) => {
              setData((prevData: any) => {
                return { ...prevData, username: e.target.value };
              });
            }}
          />
          <Typography component="div" variant="body2" marginBottom={1}>
            Password
          </Typography>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <HidepwdIcon /> : <ShowpwdIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            margin="normal"
            required
            fullWidth
            name="password"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={data.password}
            onChange={(e) => {
              setData((prevData: any) => {
                return { ...prevData, password: e.target.value };
              });
            }}
          />

          <Button
            type="submit"
            endIcon={<ArrowForward />}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <Dna
                visible={true}
                height="70"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            ) : (
              "Sign In"
            )}
          </Button>
        </Box>{" "}
        <SnackBar
          message={alertState.message}
          variant={alertState.variant}
          open={alertState.open}
          handleClose={() => {
            setAlertState((prevState: any) => {
              return { ...prevState, open: false };
            });
          }}
        />
      </>
    </>
  );
}

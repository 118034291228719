import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import SnackBar from "../../components/SnackBar";
import YSIcon from "../../assets/images/ys-icon.png";
import { Dna } from "react-loader-spinner";
import ArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  TextField,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import DnaLoading from "../../components/DnaLoading";
import { AlertType } from "../SignIn";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Cookies from "js-cookie";
import { useApiMedicSymptoms } from "../../queries/useApiMedicSymptoms";
import { useApiMedicDiagnosisPrediction } from "../../queries/useApiMedicDiagnosisPrediction";
import axios from "axios";
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const DPStyle = styled("div")(({ theme }) => ({
  width: 600,
  maxWidth: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  marginTop: 50,
  padding: 20,
  gap: 6,
  "& .dna-wrapper": {
    margin: "auto",
  },
}));
const SymtomsListStyle = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: 2,
  padding: 10,
  gap: 6,
  borderRadius: 5,
  background: "#E9ECF2",
  "& .text": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: "linear-gradient(181deg, #1A4176 0%,  #092852 50%, #1A4176 100%)",
  [theme.breakpoints.down("md")]: {
    boxShadow: "none",
  },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    [theme.breakpoints.up("md")]: {
      // marginLeft: drawerWidth,
      //   width: `calc(100% - ${drawerWidth}px)`,
      width: `100%`,
    },
    [theme.breakpoints.down("md")]: {
      //  display:'none',
      boxShadow: "none",
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MedicPredictions() {
  const [selectedOptions, setSelectedOptions] = useState<
    Array<{ ID: number; Name: string }>
  >([]);
  const [alertState, setAlertState] = useState<AlertType>({
    message: "",
    open: false,
    variant: "warning",
  });
  const [gender, setGender] = useState("male");
  const [yearOfBirth, setYearOfBirth] = useState("1947");

  const handleOptionChange = (event: React.SyntheticEvent, newValue: any) => {
    setSelectedOptions(newValue);
  };

  const getApiMedicToken = async () => {
    const credentials = `Cn5k4S3Aio7M6Erq2`;
    try {
      const api = await axios.post(
        "https://authservice.priaid.ch/login",
        {},
        {
          headers: {
            Authorization: `Bearer m9YAe_YOUNGSOFT_IN_AUT:7SBbKHy5DIPsEWwJ2i2alw==`,
          },
        }
      );
      if (api.status === 200) {
        return api?.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { isError, isInitialLoading, failureReason }: any = useQuery({
    queryKey: ["medic-token"],
    queryFn: () => getApiMedicToken(),
    refetchInterval: 28 * 60 * 1000,
    select(data) {
      Cookies.set("Medic_Token", data?.Token, {
        expires: new Date(new Date().getTime() + 50 * 60 * 1000), // expires in 50 minutes
      });
    },
  });

  const { data: mockOptions, isFetching } = useApiMedicSymptoms();

  const { data: predictions, isInitialLoading: isLoading } =
    useApiMedicDiagnosisPrediction(
      gender,
      yearOfBirth,
      selectedOptions.map((predictions) => predictions.ID)
    );

  if (isInitialLoading) {
    return <DnaLoading />;
  }

  if (isError) {
    return <h1>{failureReason?.message ?? ""}</h1>;
  }
  return (
    <>
      <AppBar position="fixed">
        <Toolbar
          sx={{
            pr: "24px",
          }}
        >
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{
              gap: 1,
              flexGrow: 1,
              margin: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={YSIcon}
              className="ysicon"
              height={30}
              alt="YSI-Icon"
              loading="lazy"
            />{" "}
            Provider App
          </Typography>
          <Box></Box>
        </Toolbar>
      </AppBar>
      <Grid
        container
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <DPStyle>
          <Typography
            variant="subtitle1"
            fontWeight={550}
            marginTop={1}
            textAlign={"left"}
            width={"100%"}
            color={"primary"}
          >
            API Medic Diagnosis Prediction
          </Typography>
          <Box
            component="form"
            // onSubmit={handleSubmit}
            noValidate
            style={{ width: "100%" }}
          >
            <select
              className="p-2 rounded m-2"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>

            <TextField
              id="year"
              className="m-2"
              name="year"
              onChange={(e) => setYearOfBirth(e.target.value)}
              value={yearOfBirth}
              placeholder="Enter year of birth"
            />
            <Autocomplete
              multiple
              value={selectedOptions}
              loading={isFetching ? true : false}
              loadingText={"Loading..."}
              noOptionsText={"Search Symptoms..."}
              onChange={handleOptionChange}
              getOptionLabel={(option) => option?.Name}
              options={mockOptions ?? []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <div key={`${option}-${index}`}>
                    <Chip label={option?.Name} {...getTagProps({ index })} />
                  </div>
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search symptoms..."
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Grid
            justifyItems={"center"}
            display={"flex"}
            justifyContent={"space-between"}
            container
          >
            <Typography fontWeight={500} variant="subtitle2" component={"div"}>
              Disease
            </Typography>
            <Typography fontWeight={500} variant="subtitle2" component={"div"}>
              Accuracy
            </Typography>
          </Grid>
          {isLoading && (
            <Dna
              visible={true}
              height="70"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          )}
          {predictions?.map((prediction: any) => (
            <SymtomsListStyle>
              <ArrowRight fontSize="small" color="primary" />
              <Box className="text">
                <Typography variant="body2" component={"span"}>
                  {prediction?.Issue?.Name}
                </Typography>
                <Typography variant="body2" component={"span"}>
                  ({prediction?.Issue?.Accuracy})
                </Typography>
              </Box>
            </SymtomsListStyle>
          ))}
        </DPStyle>
      </Grid>
      <SnackBar
        message={alertState.message}
        variant={alertState.variant}
        open={alertState.open}
        handleClose={() => {
          setAlertState((prevState: any) => {
            return { ...prevState, open: false };
          });
        }}
      />
    </>
  );
}

import { Grid, Typography } from "@mui/material";
import React from "react";

export default function AddressBook() {
  return (
    <>
      <Grid
        container
        height={"90vh"}
        flex={1}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography textAlign={"center"} variant="h3" color={"primary"}>
          Address Book - Coming soon
        </Typography>
      </Grid>
    </>
  );
}

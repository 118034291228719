import {
  TextField,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Autocomplete,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../theme";
import useDebounce from "../../hooks/useDebounce";
import { useSymptomSearch } from "../../queries/useSymptomSearch";

export default function ChiefComplaint(props: any) {
  const [selectedOptions, setSelectedOptions] = useState<
    Array<{
      icd_code: string;
      symptom_id: string;
      symptom_name: string;
    }>
  >([]);
  const [query, setQuery] = useState<string>("");

  const handleOptionChange = (event: React.SyntheticEvent, newValue: any) => {
    setSelectedOptions(newValue);
    props.setSelectedSymptoms(newValue);
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    // Handle input changes if needed
    setQuery(newInputValue);
  };

  const debounceQuery = useDebounce(query, 1000);

  const { data: mockOptions, isFetching } = useSymptomSearch(debounceQuery);
  return (
    <>
      <Card>
        <CardHeader
          title="Chief Complaint"
          action={
            <Button variant="text" size="small">
              Previous Complaints
            </Button>
          }
        />
        <CardContent>
          <Autocomplete
            id="symptoms"
            multiple
            value={selectedOptions}
            loading={isFetching ? true : false}
            loadingText={"Loading..."}
            onChange={handleOptionChange}
            noOptionsText={"Search Symptoms..."}
            getOptionLabel={(option) => option?.symptom_name}
            onInputChange={handleInputChange}
            options={mockOptions ?? []}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <div key={`${option}-${index}`}>
                  <Chip
                    label={option?.symptom_name}
                    {...getTagProps({ index })}
                  />
                </div>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search symptoms..."
                variant="outlined"
              />
            )}
          />
          {/* <Typography
            variant="body2"
            component={"div"}
            fontWeight={600}
            style={{
              color: theme.palette.primary.main,
              padding: "3px 0",
              borderBottom: "1px solid " + theme.palette.primary.main,
              marginBottom: 10,
            }}
          >
            Commonly Used:
          </Typography>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: 10,
              gap: 5,
            }}
          >
            <Button size="small" className="xs wauto" variant="outlined">
              Abdominal Pain
            </Button>
            <Button size="small" className="xs wauto" variant="outlined">
              Headache
            </Button>
            <Button size="small" className="xs wauto" variant="outlined">
              Cough
            </Button>
            <Button size="small" className="xs wauto" variant="outlined">
              Vomiting
            </Button>
            <Button size="small" className="xs wauto" variant="outlined">
              Joint Pain
            </Button>
            <Button size="small" className="xs wauto" variant="outlined">
              Weight Loss
            </Button>
            <Button size="small" className="xs wauto" variant="outlined">
              Feeling Cold(Chills)
            </Button>
            <Button size="small" className="xs" variant="outlined">
              Tiredness (Fatigue)
            </Button>
            <Button size="small" className="xs wauto" variant="outlined">
              Fever
            </Button>
            <Button size="small" className="xs wauto" variant="outlined">
              Tiredness (Fatigue)
            </Button>
            <Button size="small" className="xs wauto" variant="outlined">
              Pain
            </Button>
            <Button size="small" className="xs wauto" variant="outlined">
              Excessive sweating
            </Button>
          </Box> */}
        </CardContent>
      </Card>
    </>
  );
}

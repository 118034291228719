import { Card, CardHeader, CardContent, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { callImmunization } from "../../utils/endPoints";
import { useParams } from "react-router-dom";
const columnsImnz = [
  {
    field: "cvx_code_text",
    headerName: "Vaccine",
    minWidth: 150,
    flex: 1,
    cellClassName: "first",
    headerClassName: "first",
  },
  {
    field: "administered_date",
    headerName: "Date",
    flex: 1,
    minWidth: 150,
    cellClassName: "last",
    headerClassName: "last",
  },
];

export default function Immunizations() {
  const param: any = useParams();
  const params = new URLSearchParams({
    patient_id: param?.pid,
  });

  const {
    data: immunization,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["immunization", param?.pid],
    queryFn: () => callImmunization(params),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });
  return (
    <>
      <Card>
        <CardHeader title="Immunizations" />
        <CardContent className="h220">
          <div style={{ height: 180, width: "100%" }}>
            {isError ? (
              <Typography color="error" variant="h6">
                something went wrong!
              </Typography>
            ) : (
              <DataGrid
                rows={immunization?.data ?? []}
                columns={columnsImnz}
                autoPageSize
                hideFooter
                rowHeight={30}
                columnHeaderHeight={20}
                getRowId={(row) => row?.id}
                loading={isLoading}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
}

import { useQuery } from "@tanstack/react-query";
import { callDiagnosisPredict } from "../utils/endPoints";

export default function useDiagnosis(queryString: string) {
  const bodyData = {
    symptoms: queryString,
    count: "10",
  };
  return useQuery({
    queryKey: ["diagnosisPrediction", queryString],
    queryFn: () => callDiagnosisPredict(bodyData),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !!queryString,
  });
}

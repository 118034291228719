import { useState } from "react";
import ChiefComplaint from "../../../../components/ChiefComplaint";
import PastMedicalHistory from "../../../../components/PastMedicalHistory";
import VitalsAnthropometric from "../../../../components/VitalsAnthropometric";
import Diagnosis from "../../../../components/Diagnosis";
import DiagnosisTests from "../../../../components/DiagnosisTests";
import PreventiveMeasures from "../../../../components/PreventiveMeasures";
import Medications from "../../../../components/Medications";
import PrescriptionPad from "../../../../components/PrescriptionPad";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import VideocamIcon from "@mui/icons-material/Videocam";
import recallIcon from "../../../../assets/images/recall-icon.svg";
import referralsIcon from "../../../../assets/images/referrals-icon.svg";
import RecallsPopup from "../../../../components/RecallsPopup";
import ReferralsPopup from "../../../../components/ReferralsPopup";
import { useSummary } from "../../../../queries/useSummary";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  callAppointment,
  callEachPatientsListEndPoint,
  callMedicalProblemDetails,
  callPatientMedications,
} from "../../../../utils/endPoints";
import { useParams } from "react-router-dom";
import moment from "moment";
import ClinicalNotesMenu from "./ClinicalNotesMenu";

export default function ConsultationPanel() {
  const param: any = useParams();
  const [selectedDiseaseId, setSelectedDiseaseId] = useState("");
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [medicationsData, setMedicationsData] = useState([]);
  const [diagnosisTestData, setDiagnosisTestData] = useState([]);
  const [PreventiveMeasuresData, setPreventiveMeasuresData] = useState([]);
  const [recallsOpen, setRecallsOpen] = useState(false);
  const [referralsOpen, setReferralsOpen] = useState(false);
  const [clinicNotes, setClinicNotes] = useState("");
  const handleClickRecallsOpen = () => {
    setRecallsOpen(true);
  };

  const handleRecallsClose = () => {
    setRecallsOpen(false);
  };

  const handleClickReferralsOpen = () => {
    setReferralsOpen(true);
  };

  const handleReferralsClose = () => {
    setReferralsOpen(false);
  };
  const { data: patientData }: any = useQuery({
    queryKey: ["patients", param.puuid],
    queryFn: () => callEachPatientsListEndPoint(param.puuid),
    staleTime: 10000,
  });

  const { data: medications } = useQuery({
    queryKey: ["medications", param?.pid],
    queryFn: () => callPatientMedications(param?.pid ?? ""),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });

  const { data: apptData }: any = useQuery({
    queryKey: ["appointment", param],
    queryFn: () => callAppointment(param),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });

  const { data: medicalProblems }: any = useQuery({
    queryKey: ["medical_problems", param],
    queryFn: () => callMedicalProblemDetails(param),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });

  const body = {
    age: moment().diff(patientData?.data?.DOB, "years").toString(),
    gender: patientData?.data?.sex,
    reason_for_visit: apptData?.[0]?.reason ?? "",
    current_symptoms: "",
    current_medications: medications
      ?.map((medication: any) => medication?.title)
      ?.join(", "),
    medical_history: medicalProblems?.data
      ?.map((problem: any) => problem?.title)
      ?.join(", "),
  };

  const { data, isLoading, isError } = useSummary(
    body,
    patientData && medications && medicalProblems && apptData
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button variant="outlined" className="whitebg" size="small">
            Send Video Chat Link
          </Button>
          <Button variant="outlined" className="whitebg" size="small">
            Past Medical Records
          </Button>
          <MusicNoteIcon color="primary" /> <VideocamIcon color="primary" />
        </Box>
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={2}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Summary" />
              <CardContent>
                {isLoading ? (
                  <LinearProgress />
                ) : isError ? (
                  <Typography color={"error"} variant="body2" component={"div"}>
                    Something went wrong!
                  </Typography>
                ) : (
                  <Typography
                    color={"primary"}
                    variant="body2"
                    component={"div"}
                  >
                    {data ?? "Not Available"}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <Grid
              container
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={2}
            >
              <Grid item xs={12}>
                <ChiefComplaint setSelectedSymptoms={setSelectedSymptoms} />
              </Grid>
              <Grid item xs={12}>
                <DiagnosisTests
                  selectedDiseaseId={selectedDiseaseId}
                  diagnosisTestData={diagnosisTestData}
                  setDiagnosisTestData={setDiagnosisTestData}
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <Medications
                  selectedDiseaseId={selectedDiseaseId}
                  setMedicationsData={setMedicationsData}
                  medicationsData={medicationsData}
                />
              </Grid>
              <Grid item xs={12}>
                <PreventiveMeasures
                  selectedDiseaseId={selectedDiseaseId}
                  setPreventiveMeasuresData={setPreventiveMeasuresData}
                  PreventiveMeasuresData={PreventiveMeasuresData}
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <PastMedicalHistory />
              </Grid>{" "}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={2}
            >
              <Grid item xs={12}>
                <Diagnosis
                  selectedSymptoms={selectedSymptoms}
                  setSelectedDiseaseId={setSelectedDiseaseId}
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <PrescriptionPad
                  medicationsData={medicationsData}
                  diagnosisTestData={diagnosisTestData}
                  PreventiveMeasuresData={PreventiveMeasuresData}
                />
              </Grid>{" "}
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Clinical Notes" />
                  <CardContent>
                    <Grid
                      container
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      spacing={3}
                    >
                      <Grid item xs={12} md={8}>
                        <TextareaAutosize
                          style={{ width: "100%", height: 190 }}
                          value={clinicNotes}
                          onChange={(e: any) => setClinicNotes(e.target.value)}
                        />
                      </Grid>{" "}
                      <Grid item xs={12} md={4}>
                        <ClinicalNotesMenu
                          patientData={patientData?.data}
                          setClinicNotes={setClinicNotes}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <VitalsAnthropometric />
              </Grid>{" "}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent={"center"}
          alignItems={"center"}
          spacing={3}
          marginTop={1}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: "100%",
                maxWidth: 300,
                margin: "auto",
                marginBottom: 1,
              }}
            >
              <Button fullWidth variant="contained" size="large">
                Save
              </Button>
              <Button fullWidth variant="contained" size="large">
                Submit
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: "100%",
                maxWidth: 300,
                margin: "auto",
                marginBottom: 1,
              }}
            >
              <Button
                fullWidth
                variant="outlined"
                className="whitebg"
                size="large"
                startIcon={<img src={recallIcon} alt="recall" />}
                onClick={handleClickRecallsOpen}
              >
                Recall
              </Button>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                className="whitebg"
                startIcon={<img src={referralsIcon} alt="referral" />}
                onClick={handleClickReferralsOpen}
              >
                Referrals
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ReferralsPopup
        referralsOpen={referralsOpen}
        handleReferralsClose={handleReferralsClose}
      />
      <RecallsPopup
        recallsOpen={recallsOpen}
        handleRecallsClose={handleRecallsClose}
      />
    </>
  );
}

import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { PTSWidgetStyle } from "../Upcoming";
import PTSWidget from "../../../components/PTSWidget";
import DnaLoading from "../../../components/DnaLoading";
import { callAppointments } from "../../../utils/endPoints";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
export default function Completed() {
  const navigate = useNavigate();
  const [DWMState, setDWMState] = useState<string>("D");

  const filteredData = useCallback(
    (data: any) => {
      if (data) {
        return data.filter(({ pc_eventDate, pc_endTime }: any) => {
          const currentDate = moment();
          const itemDate = moment(`${pc_eventDate} ${pc_endTime}`);

          if (DWMState === "D") {
            const previousOneDay = moment().subtract(1, "day");
            return itemDate.isBetween(previousOneDay, currentDate);
          } else if (DWMState === "M") {
            const previousOneMonth = moment().subtract(1, "month");
            return itemDate.isBetween(previousOneMonth, currentDate);
          } else if (DWMState === "W") {
            const previousOneWeek = moment().subtract(1, "week");
            return itemDate.isBetween(previousOneWeek, currentDate);
          } else if (DWMState === "All") {
            return pc_eventDate;
          }

          return true;
        });
      }
      return [];
    },
    [DWMState]
  );

  const { isError, isLoading, data, failureReason }: any = useQuery({
    queryKey: ["appointments"],
    queryFn: () => callAppointments(),
    select: filteredData,
  });

  if (isLoading) {
    return <DnaLoading />;
  }

  if (isError) {
    return <h1>{failureReason?.message ?? ""}</h1>;
  }

  return (
    <>
      <Grid
        container
        item
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={3}
      >
        <Grid item xs={12} md={6}>
          <Box
            style={{
              display: "flex",
              gap: 2,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {/* <Tooltip title="Prev Date">
              <IconButton>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip> */}
            <Typography className="today" variant="subtitle1">
              {moment().format("MMMM Do YYYY")}
            </Typography>
            {/* <Tooltip title="Next Date">
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </Tooltip> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonGroup
            className="DWM"
            variant="outlined"
            aria-label="outlined button group"
          >
            <Button
              onClick={() => setDWMState("D")}
              className={DWMState === "D" ? "active" : ""}
            >
              D
            </Button>
            <Button
              onClick={() => setDWMState("W")}
              className={DWMState === "W" ? "active" : ""}
            >
              W
            </Button>
            <Button
              onClick={() => setDWMState("M")}
              className={DWMState === "M" ? "active" : ""}
            >
              M
            </Button>
            <Button
              onClick={() => setDWMState("All")}
              className={DWMState === "All" ? "active" : ""}
            >
              All
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <PTSWidgetStyle>
            <Grid
              container
              justifyContent={"flex-start"}
              alignItems={"center"}
              spacing={2}
            >
              {data.length > 0 ? (
                data.map((eachItem: any) => (
                  <Grid key={eachItem?.pc_eid} item xs={12} sm={6} md={4}>
                    <PTSWidget
                      key={eachItem?.pc_eid}
                      onClick={() =>
                        navigate(
                          `${eachItem?.pc_eid}/${eachItem?.pid}/${eachItem?.puuid}`
                        )
                      }
                      name={eachItem?.fname + " " + eachItem?.lname}
                      time={eachItem?.pc_eventDate}
                      gender={eachItem?.sex ?? "NA"}
                      age={
                        moment().diff(eachItem?.DOB, "years").toString() +
                        " Yrs"
                      }
                      startTime={eachItem?.pc_startTime}
                      endTime={eachItem?.pc_endTime}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Alert variant="outlined" severity="info">
                    <Typography component={"div"} variant="subtitle2">
                      No Results
                    </Typography>
                    <Typography component={"div"} variant="caption">
                      Sorry there are no results for this filters
                    </Typography>
                  </Alert>
                </Grid>
              )}
            </Grid>
          </PTSWidgetStyle>
        </Grid>
      </Grid>
    </>
  );
}

import { Box, Grid, Typography } from "@mui/material";
import ReasonforVisit from "../../../../components/ReasonforVisit";
import IssuesMedicalProblems from "../../../../components/IssuesMedicalProblems";
import Vitals from "../../../../components/Vitals";
import ReviewOfSystems from "../../../../components/ReviewOfSystems";
import Immunizations from "../../../../components/Immunizations";
import ReportsDocuments from "../../../../components/ReportsDocuments";
import SOAPNotes from "../../../../components/SOAPNotes";
import { useParams } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { callAppointment } from "../../../../utils/endPoints";

export default function IntakeFormPanel() {
  const params: any = useParams();

  const { data }: any = useQuery({
    queryKey: ["appointment", params],
    queryFn: () => callAppointment(params),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        <Typography variant="body1" component={"div"}>
          Encounter Date and Time: {data?.[0]?.pc_eventDate}{" "}
          {data?.[0]?.pc_startTime}
        </Typography>
        <Grid
          container
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={3}
        >
          <Grid item xs={12}>
            <ReasonforVisit reason={data?.[0]?.reason} />
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <IssuesMedicalProblems />
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <Vitals />
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <ReviewOfSystems />
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <Immunizations />
          </Grid>{" "}
          <Grid item xs={12} md={7}>
            <ReportsDocuments />
          </Grid>{" "}
          <Grid item xs={12} md={5}>
            <SOAPNotes />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

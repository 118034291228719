import { useQuery } from "@tanstack/react-query";
import { callSymptomSearch } from "../utils/endPoints";

export const useSymptomSearch = (queryString: string) => {
  const bodyData = {
    symptom: queryString,
  };
  return useQuery({
    queryKey: ["SymptomSearch", queryString],
    queryFn: () => callSymptomSearch(bodyData),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !!queryString,
  });
};

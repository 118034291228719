import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { styled, createTheme } from "@mui/material/styles";
const defaultTheme = createTheme();

export const CopyrightStyle = styled("div")(({ theme }) => ({
  bottom: 0,
  width: "100%",
  padding: 5,
  textAlign: "center",
  [defaultTheme.breakpoints.down("md")]: { display: "none" },
}));

function Copyright() {
  return (
    <>
      <CopyrightStyle>
        <Typography variant="body2" color="text.secondary">
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="https://youngsoft.in/"
          >
            {"© "} {new Date().getFullYear()} Youngsoft Inc.,
          </Link>
        </Typography>
      </CopyrightStyle>
    </>
  );
}

export default React.memo(Copyright);

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  LinearProgress,
} from "@mui/material";
import Button from "@mui/material/Button";
import { callMedicalProblemDetails } from "../../utils/endPoints";
import { useParams } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export default function IssuesMedicalProblems() {
  const params: any = useParams();
  const { data, isLoading, isError }: any = useQuery({
    queryKey: ["medical_problems", params],
    queryFn: () => callMedicalProblemDetails(params),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });
  return (
    <>
      <Card>
        <CardHeader title="Issues/Medical Problems" />
        {isLoading ? (
          <Box>
            <LinearProgress />
          </Box>
        ) : isError ? (
          <Typography color="error" variant="h6">
            something went wrong!
          </Typography>
        ) : (
          <CardContent className="h150">
            <Box style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
              {data?.data?.map((each: any) => (
                <Button
                  key={each?.id}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ pointerEvents: "none" }}
                >
                  {each?.title}
                </Button>
              ))}
            </Box>
          </CardContent>
        )}
      </Card>
    </>
  );
}

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  LinearProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React, { useEffect, useState } from "react";
import { theme } from "../../theme";
import useDiagnosis from "../../queries/useDiagnosis";

export default function Diagnosis(props: any) {
  const [selectedDisease, setSelectedDisease] = useState("");
  const { data, isInitialLoading, isError, error }: any = useDiagnosis(
    props.selectedSymptoms
      .map((predictions: any) => predictions.symptom_name)
      .join(",")
  );

  useEffect(() => {
    props.setSelectedDiseaseId(selectedDisease);
  }, [selectedDisease]);

  return (
    <>
      <Card>
        <CardHeader
          title="Diagnosis"
          action={
            <Button variant="text" size="small">
              Previous Diagnosis
            </Button>
          }
        />{" "}
        <CardContent>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Provisional"
            />
            <FormControlLabel control={<Checkbox />} label="Final" />
          </FormGroup>

          <Typography
            variant="body2"
            component={"div"}
            fontWeight={600}
            style={{
              color: theme.palette.primary.main,
              padding: "3px 0",
              borderBottom: "1px solid " + theme.palette.primary.main,
              marginBottom: 10,
            }}
          >
            Differential Diagnosis
          </Typography>

          <Box className="AddToList">
            {isInitialLoading ? (
              <LinearProgress />
            ) : isError ? (
              <Box>
                <Typography variant="body2" component={"div"}>
                  {error?.message}
                </Typography>
              </Box>
            ) : data?.length > 0 ? (
              data?.map((each: any) => (
                <Box key={each?.disease_id}>
                  <CoronavirusIcon fontSize="small" color="primary" />
                  <Typography variant="body2" component={"div"}>
                    {each?.name} - {each?.accuracy}
                  </Typography>
                  <Button
                    variant="text"
                    size="small"
                    className="xs"
                    startIcon={
                      selectedDisease === each?.disease_id ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )
                    }
                    onClick={() => {
                      setSelectedDisease(
                        selectedDisease === each?.disease_id
                          ? ""
                          : each?.disease_id
                      );
                    }}
                  >
                    {selectedDisease === each?.disease_id
                      ? "Deselect"
                      : "Select"}
                  </Button>
                </Box>
              ))
            ) : (
              <Box>
                <Typography variant="body2" component={"div"}>
                  Please search for chief complaint
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

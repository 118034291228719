// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Box, Grid, Tab, Typography } from "@mui/material";

// utils

// components
import { theme } from "../../theme";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

PTSWidget.propTypes = {
  onClick: PropTypes.func,
  time: PropTypes.string,
  name: PropTypes.string,
  gender: PropTypes.string,
  age: PropTypes.string,
  ptid: PropTypes.string,
  lastvisit: PropTypes.string,
  sx: PropTypes.object,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};
export default function PTSWidget({
  time,
  name,
  gender,
  age,
  ptid,
  lastvisit,
  onClick,
  color = "primary",
  sx,
  startTime,
  endTime,
  ...other
}: any) {
  return (
    <Card
      sx={{
        width: "100%",
        py: 0,
        transition: "all 0.5s ease-out",

        "&:hover ": {
          transform: "scale(1.01)",
          boxShadow: "0 0 10px 2px #48abe066",
        },

        minWidth: 250,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: 1,
        gap: 1,
        borderRadius: 2,
        "& .time": { minWidth: 80 },
        "& .card": {
          width: "100%",
          padding: 2,
          borderRadius: 1,
          border: "none",
          display: "flex",
          flexDirection: "column",
          background: "#f2f2f2",
          gap: 1,
          justifyContent: "space-between",
          [theme.breakpoints.down("md")]: {
            justifyContent: "space-between",
            background: "#fff",
          },
          "& span": {
            color: "#999",
          },
          "& .MuiBox-root": {
            display: "flex",
            gap: 1,
          },
        },
        cursor: "pointer",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        textAlign: "left",
        color: theme.palette.primary.dark,

        ...sx,
        [theme.breakpoints.up("sm")]: {
          background: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
          background: "transparent",
          position: "relative",
          padding: 0,
          boxShadow: "none",
          border: "none !important",
        },
      }}
      onClick={onClick}
      {...other}
    >
      <Box className="time">
        <Typography variant="body2"> {time} </Typography>
      </Box>
      <Box className="card">
        <Box>
          <Typography variant="subtitle2" color={"primary"}>
            {name}
          </Typography>
          <Typography variant="body2" component={"span"}>
            |
          </Typography>
          <Typography variant="body2">{gender} </Typography>
          <Typography variant="body2" component={"span"}>
            |
          </Typography>
          <Typography variant="body2">{age}</Typography>
        </Box>
        <Box>
          <Typography variant="caption" component={"div"}>
            {startTime} - {endTime}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

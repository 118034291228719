import { Card, CardHeader, CardContent, Button } from "@mui/material";

export default function PastMedicalHistory() {
  return (
    <>
      <Card>
        <CardHeader
          title="Past Medical History"
          action={
            <Button variant="text" size="small">
              Patient Entered History
            </Button>
          }
        />
        <CardContent className="flex">
          <Button variant="outlined" color="primary" size="small">
            Diseases
          </Button>
          <Button variant="outlined" color="primary" size="small">
            Allergy History
          </Button>
          <Button variant="outlined" color="primary" size="small">
            Social History
          </Button>
          <Button variant="outlined" color="primary" size="small">
            Surgical History
          </Button>
          <Button variant="outlined" color="primary" size="small">
            Family History
          </Button>
        </CardContent>
      </Card>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { TextField, Grid, Slide, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Select, MenuItem, FormControl } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction="left"
      timeout={{ enter: 2000, exit: 400 }}
      ref={ref}
      {...props}
    />
  );
});

const initialValues = {
  drugName: "",
  patientName: "",
  recallDate: "",
  recallReason: "",
  referringDoctor: "",
  referredDoctor: "",
  referralReason: "",
  rxStartingDate: "",
  rxProviderName: localStorage.getItem("providerName") ?? "",
  rxQuantity: "",
  rxMedicineUnits: "",
  rxMedicineUnitsType: "0",
  rxDirections: "",
  rxDirectionsMedType: "0",
  rxDirectionsType: "0",
  rxDirectionsTime: "0",
  rxRefills: "0",
  rxRefillsCount: "",
  rxNotes: "",
};
export default function PrescriptionPopup({
  medName,
  setMedicationsData,
  handleRxClose,
  rxOpen,
}: any) {
  const [formData, setFormData] = useState(initialValues);

  const handleRxSubmit = (e: any) => {
    e.preventDefault();
    setMedicationsData((prev: any) => [...prev, formData]);
    setFormData(initialValues);
    handleRxClose();
  };
  const handleRxChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormData({ ...formData, drugName: medName });
  }, [formData, medName]);

  return (
    <>
      <Dialog
        open={rxOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleRxClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: 800, // Set your width here
            },
          },
        }}
      >
        <form onSubmit={handleRxSubmit}>
          <DialogTitle>{"Prescription  "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                spacing={1}
              >
                <Grid item xs={12}>
                  <Typography variant="h6">{medName}</Typography>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <Grid item xs={12} md={4}>
                      <Typography variant="body2" component={"label"}>
                        Starting Date
                      </Typography>
                      <TextField
                        name="rxStartingDate"
                        type="date"
                        fullWidth
                        value={formData.rxStartingDate}
                        onChange={handleRxChange}
                        margin="normal"
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Typography variant="body2" component={"label"}>
                        Provider Name
                      </Typography>
                      <TextField
                        name="rxProviderName"
                        fullWidth
                        value={formData.rxProviderName}
                        onChange={handleRxChange}
                        margin="normal"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography variant="body2" component={"label"}>
                        Quantity
                      </Typography>
                      <TextField
                        name="rxQuantity"
                        fullWidth
                        value={formData.rxQuantity}
                        onChange={handleRxChange}
                        margin="normal"
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>{" "}
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <Typography variant="body2" component={"label"}>
                        Medicine Units
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="rxMedicineUnits"
                        fullWidth
                        value={formData.rxMedicineUnits}
                        onChange={handleRxChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        name="rxMedicineUnitsType"
                        value={formData.rxMedicineUnitsType}
                        onChange={handleRxChange}
                        fullWidth
                      >
                        <MenuItem value="0">Select</MenuItem>
                        <MenuItem value="1">mg</MenuItem>
                        <MenuItem value="2">mg/1cc</MenuItem>
                        <MenuItem value="3">mg/2cc</MenuItem>
                        <MenuItem value="4">mg/3cc</MenuItem>
                        <MenuItem value="5">mg/4cc</MenuItem>
                        <MenuItem value="6">mg/5cc</MenuItem>
                        <MenuItem value="7">mcg</MenuItem>
                        <MenuItem value="8">grams</MenuItem>
                        <MenuItem value="9">mL</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>{" "}
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <Typography variant="body2" component={"label"}>
                        Directions
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        name="rxDirections"
                        fullWidth
                        value={formData.rxDirections}
                        onChange={handleRxChange}
                        margin="normal"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Typography variant="body1" component={"label"}>
                        in
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth margin="normal" required>
                        <Select
                          name="rxDirectionsMedType"
                          value={formData.rxDirectionsMedType}
                          onChange={handleRxChange}
                        >
                          <MenuItem value="0">Select</MenuItem>
                          <MenuItem value="1">suspension</MenuItem>
                          <MenuItem value="2">tablet</MenuItem>
                          <MenuItem value="3">capsule</MenuItem>
                          <MenuItem value="4">solution</MenuItem>
                          <MenuItem value="5">tsp</MenuItem>
                          <MenuItem value="6">ml</MenuItem>
                          <MenuItem value="7">units</MenuItem>
                          <MenuItem value="8">inhalations</MenuItem>
                          <MenuItem value="9">gtts(drops)</MenuItem>
                          <MenuItem value="10">cream</MenuItem>
                          <MenuItem value="11">ointment</MenuItem>
                          <MenuItem value="12">puff</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth margin="normal" required>
                        <Select
                          name="rxDirectionsType"
                          value={formData.rxDirectionsType}
                          onChange={handleRxChange}
                        >
                          <MenuItem value="0">Select</MenuItem>
                          <MenuItem value="1">Per Oris</MenuItem>
                          <MenuItem value="2">By Mouth</MenuItem>
                          <MenuItem value="3">Per Rectum</MenuItem>
                          <MenuItem value="4">To Skin</MenuItem>
                          <MenuItem value="5">To Affected Area</MenuItem>
                          <MenuItem value="6">Sublingual</MenuItem>
                          <MenuItem value="7">OS</MenuItem>
                          <MenuItem value="8">OD</MenuItem>
                          <MenuItem value="9">OU</MenuItem>
                          <MenuItem value="10">SQ</MenuItem>
                          <MenuItem value="11">IM</MenuItem>
                          <MenuItem value="12">IV</MenuItem>
                          <MenuItem value="13">Per Nostril</MenuItem>
                          <MenuItem value="14">Both Ears</MenuItem>
                          <MenuItem value="15">Left Ear</MenuItem>
                          <MenuItem value="16">Right Ear</MenuItem>
                          <MenuItem value="17">Inhale</MenuItem>
                          <MenuItem value="18">Intradermal</MenuItem>
                          <MenuItem value="19">Other/Miscellaneous</MenuItem>
                          <MenuItem value="20">Transdermal</MenuItem>
                          <MenuItem value="21">Intramuscular</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth margin="normal" required>
                        <Select
                          name="rxDirectionsTime"
                          value={formData.rxDirectionsTime}
                          onChange={handleRxChange}
                        >
                          <MenuItem value="0">Select</MenuItem>
                          <MenuItem value="1">b.i.d.</MenuItem>
                          <MenuItem value="2">t.i.d.</MenuItem>
                          <MenuItem value="3">q.i.d.</MenuItem>
                          <MenuItem value="4">q.3h</MenuItem>
                          <MenuItem value="5">q.4h</MenuItem>
                          <MenuItem value="6">q.5h</MenuItem>
                          <MenuItem value="7">q.6h</MenuItem>
                          <MenuItem value="8">q.8h</MenuItem>
                          <MenuItem value="9">q.d.</MenuItem>
                          <MenuItem value="10">a.c.</MenuItem>
                          <MenuItem value="11">p.c.</MenuItem>
                          <MenuItem value="12">a.m.</MenuItem>
                          <MenuItem value="13">p.m.</MenuItem>
                          <MenuItem value="14">ante</MenuItem>
                          <MenuItem value="15">h</MenuItem>
                          <MenuItem value="16">h.s.</MenuItem>
                          <MenuItem value="17">p.r.n.</MenuItem>
                          <MenuItem value="18">stat</MenuItem>
                          <MenuItem value="19">Weekly</MenuItem>
                          <MenuItem value="20">Monthly</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>{" "}
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <Typography variant="body2" component={"label"}>
                        Refills
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth margin="normal" required>
                        <Select
                          name="rxRefills"
                          value={formData.rxRefills}
                          onChange={handleRxChange}
                        >
                          <MenuItem value="0">00</MenuItem>
                          <MenuItem value="1">01</MenuItem>
                          <MenuItem value="2">02</MenuItem>
                          <MenuItem value="3">03</MenuItem>
                          <MenuItem value="4">04</MenuItem>
                          <MenuItem value="5">05</MenuItem>
                          <MenuItem value="6">06</MenuItem>
                          <MenuItem value="7">07</MenuItem>
                          <MenuItem value="8">08</MenuItem>
                          <MenuItem value="9">09</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="11">11</MenuItem>
                          <MenuItem value="12">12</MenuItem>
                          <MenuItem value="13">13</MenuItem>
                          <MenuItem value="14">14</MenuItem>
                          <MenuItem value="15">15</MenuItem>
                          <MenuItem value="16">16</MenuItem>
                          <MenuItem value="17">17</MenuItem>
                          <MenuItem value="18">18</MenuItem>
                          <MenuItem value="19">19</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="rxRefillsCount"
                        placeholder="# of tablets"
                        fullWidth
                        value={formData.rxRefillsCount}
                        onChange={handleRxChange}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Grid>{" "}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

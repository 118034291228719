import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import Slide, { SlideProps } from "@mui/material/Slide";

interface SnackbarProps {
  variant: AlertColor;
  open: boolean;
  handleClose: () => void;
  message: string;
}
type TransitionProps = Omit<SlideProps, "direction">;

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

export default function SnackBar({
  variant,
  open,
  message,
  handleClose,
}: SnackbarProps) {
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        TransitionComponent={TransitionLeft}
      >
        <Alert onClose={handleClose} severity={variant}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

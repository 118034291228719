import { useQuery } from "@tanstack/react-query";
import { callDiagnosis } from "../utils/endPoints";
import Cookies from "js-cookie";

export const useApiMedicDiagnosisPrediction = (
  gender: any,
  year_of_birth: any,
  selectedSymptoms: any
) => {
  const params: any = new URLSearchParams();
  params.append("token", Cookies.get("Medic_Token"));
  params.append("gender", gender);
  params.append("year_of_birth", year_of_birth);
  params.append("language", "en-gb");
  params.append("symptoms", `[${selectedSymptoms}]`);

  return useQuery({
    queryKey: [
      "apiMedic-diagnosis",
      {
        symptoms: selectedSymptoms,
        gender: gender,
        year_of_birth: year_of_birth,
      },
    ],
    queryFn: () => callDiagnosis(params),
    enabled: selectedSymptoms?.length > 0,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};

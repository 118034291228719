import Circle from "@mui/icons-material/Circle";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavLink, useParams } from "react-router-dom";
import {
  AllergiesIcon,
  ClinicalNotesIcon,
  ConsentFormsIcon,
  DemographicsIcon,
  DoctorIcon,
  DocumentsIcon,
  EmailOIcon,
  LabOrdersIcon,
  LocationIcon,
  MedicationsIcon,
  PastEncountersIcon,
  PaymentTransactionsIcon,
  PhoneIcon,
  RadiobulletIcon,
  RecallsIcon,
  RefferalsIcon,
  ReportsIcon,
  SymptomsIcon,
  TreatmentPlansIcon,
  VitalsIcon,
} from "../../assets/icons";
import { useQuery } from "@tanstack/react-query";
import { callEachPatientsListEndPoint } from "../../utils/endPoints";
import moment from "moment";
import DnaLoading from "../../components/DnaLoading";
import PatientTimeline from "../../components/PatientTimeline";

const OtherLinks = [
  {
    name: "Vitals",
    link: "#",
    Icon: VitalsIcon,
  },
  {
    name: "Demographics",
    link: "#",
    Icon: DemographicsIcon ,
  },
  {
    name: "Medications",
    link: "#",
    Icon: MedicationsIcon ,
  },
  {
    name: "Lab Orders",
    link: "#",
    Icon: LabOrdersIcon,
  },
  {
    name: "Symptoms",
    link: "#",
    Icon: SymptomsIcon ,
  },
  {
    name: "Allergies",
    link: "#",
    Icon: AllergiesIcon,
  },
  {
    name: "Past Encounters",
    link: "#",
    Icon: PastEncountersIcon ,
  },
  {
    name: "Recalls",
    link: "#",
    Icon: RecallsIcon ,
  },
  {
    name: "Referrals",
    link: "#",
    Icon: RefferalsIcon ,
  },
  {
    name: "Consent Forms",
    link: "#",
    Icon: ConsentFormsIcon ,
  },
  {
    name: "Documents",
    link: "#",
    Icon: DocumentsIcon ,
  },
  {
    name: "Payment Transaction",
    link: "#",
    Icon: PaymentTransactionsIcon ,
  },
  {
    name: "Reports",
    link: "/app/reports",
    Icon: ReportsIcon ,
  },
  {
    name: "Treatment Plans",
    link: "#",
    Icon: TreatmentPlansIcon ,
  },
  {
    name: "Clinical Notes",
    link: "#",
    Icon: ClinicalNotesIcon ,
  },
];
const PatientDetailsStyle = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: 160,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  marginTop: 2,
  padding: 20,
  gap: 6,
  boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)",
  borderTopRightRadius: 20,
  borderTopLeftRadius: 20,
  [theme.breakpoints.down("sm")]: {
    // overflow: "auto",
    // height: "calc(100vh - 280px)",
  },
}));

const IconLink = styled(NavLink)(({ theme }) => ({
  transition: "all 0.5s ease-out",
  background:
    "linear-gradient(180deg, rgb(244 249 255) 0%, rgb(137 162 193 / 39%) 100%)",
  "&:hover ": {
    transform: "scale(1.01)",
    boxShadow: "0 0 5px 2px #48abe066",
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: 20,
  gap: 10,
  borderRadius: 15,
  width: "100%",
  height: 100,
  border: "0.5px solid #466DBB",
  boxShadow: "0px 4px 4px 0px rgb(166 186 220)",
  textAlign: "center",
  textDecoration: "none",
  color: "#000",
}));
const IconText = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: 0,
  gap: 10,
  "& .MuiBox-root": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 2,
  },
  "& a": {
    color: theme.palette.primary.light,
    textDecoration: "none",
  },
  "& svg": {
    color: theme.palette.primary.light,
    width: 16,
  },
  "& .Box": {
    padding: 10,
    borderRadius: 10,
    border: "1px solid rgba(26, 65, 118, 0.25)",
  },
}));

const BoxStyle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  padding: 20,
  gap: 10,
  background: "#ffffff47",
  [theme.breakpoints.up("md")]: {
    minHeight: 120,
  },
  borderRadius: 10,
  marginTop: 5,
  border: "1px solid #ccc",
  "& .icon": {
    display: "flex",
    gap: 10,
    "& .MuiTypography-body2": {
      marginTop: 3,
    },
  },

  "& .visit-dates": {
    display: "flex",
    marginTop: 5,
    gap: 3,
    "& .date": {
      [theme.breakpoints.down("md")]: {
        // display: "none",
      },
    },
    "& .MuiTypography-root": {
      [theme.breakpoints.up("md")]: {
        width: 50,
      },
      textAlign: "center",
    },
  },
}));

export function PtInfo({ data }: any) {
  return (
    <>
      <Box
        sx={{
          background: "#446CBA",
          marginBottom: -2,
          color: "white",
          padding: 3,
        }}
      >
        <Grid
          container
          justifyContent={"center"}
          alignItems={"top"}
          spacing={0}
        >
          {" "}
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ background: "orange", height: 60, width: 60 }}>
                {data?.data?.fname[0]} {data?.data?.lname[0]}
              </Avatar>
              <Typography className="name" variant="subtitle1">
                {data?.data?.title} {data?.data?.fname} {data?.data?.lname}
              </Typography>{" "}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    className="lbl"
                    sx={{ opacity: 0.6 }}
                    variant="caption"
                  >
                    Patient ID:{" "}
                  </Typography>
                  <Typography className="val" variant="caption">
                    {data?.data?.pid}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    className="lbl"
                    sx={{ opacity: 0.6 }}
                    variant="caption"
                  >
                    Last Visit:{" "}
                  </Typography>
                  <Typography className="val" variant="caption">
                    {" "}
                    {data?.data?.date ? data?.data?.date : "NA"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    className="lbl"
                    sx={{ opacity: 0.6 }}
                    variant="caption"
                  >
                    Gender:{" "}
                  </Typography>
                  <Typography className="val" variant="caption">
                    {data?.data?.sex ? data?.data?.sex : "NA"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    className="lbl"
                    sx={{ opacity: 0.6 }}
                    variant="caption"
                  >
                    DOB:{" "}
                  </Typography>
                  <Typography className="val" variant="caption">
                    {" "}
                    {moment(data?.data?.DOB).format("MM/DD/YYYY")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    className="lbl"
                    sx={{ opacity: 0.6 }}
                    variant="caption"
                  >
                    Marital Status:
                  </Typography>{" "}
                  <Typography className="val" variant="caption">
                    {data?.data?.status ? data?.data?.status : "NA"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    className="lbl"
                    sx={{ opacity: 0.6 }}
                    variant="caption"
                  >
                    Language:{" "}
                  </Typography>
                  <Typography className="val" variant="caption">
                    {data?.data?.language ? data?.data?.language : "NA"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    className="lbl"
                    sx={{ opacity: 0.6 }}
                    variant="caption"
                  >
                    Ethnicity:
                  </Typography>
                  <Typography className="val" variant="caption">
                    {data?.data?.ethnicity ? data?.data?.ethnicity : "NA"}
                  </Typography>
                </Box>
              </Box>
            </Box>{" "}
          </Grid>
          <Grid item xs={12} md={3}>
            <IconText>
              <DoctorIcon />
              <Box>
                <Typography variant="caption" component={"div"}>
                  <Typography sx={{ opacity: 0.6 }} variant="caption">
                    Primary Provider:
                  </Typography>{" "}
                  xxxx
                </Typography>
              </Box>
            </IconText>{" "}
            <IconText>
              <LocationIcon />
              <Typography variant="caption" component={"div"}>
                {data?.data?.street}, {data?.data?.city}, {data?.data?.state},{" "}
                {data?.data?.country_code}.
              </Typography>
            </IconText>{" "}
          </Grid>
          <Grid item xs={12} md={2}>
            <IconText>
              <PhoneIcon />
              <Typography variant="caption" component={"div"}>
                {data?.data?.phone_home ? data?.data?.phone_home : "NA"}
              </Typography>
            </IconText>
            <IconText>
              <EmailOIcon />
              <Typography
                variant="body2"
                textAlign={"center"}
                component={"div"}
              >
                <a href={data?.data?.email}>
                  {data?.data?.email ? data?.data?.email : "NA"}
                </a>
              </Typography>
            </IconText>
          </Grid>
        </Grid>
      </Box>
      <Box className="TopLeftRightRadius"></Box>
    </>
  );
}
export default function PatientDetails() {
  const { uuid }: any = useParams();
  const { isError, isLoading, data, failureReason }: any = useQuery({
    queryKey: ["patients", uuid],
    queryFn: () => callEachPatientsListEndPoint(uuid),
    staleTime: 10000,
  });

  if (isLoading) {
    return <DnaLoading />;
  }

  if (isError) {
    return <>{failureReason?.message}</>;
  }
  return (
    <>
      <PtInfo data={data} />

      <PatientDetailsStyle>
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"top"}
          spacing={1}
        >
          <Grid item xs={12} md={6}>
            <Typography marginTop={2} variant="body1" fontWeight={"bold"}>
              Summary
            </Typography>
            <BoxStyle>
              <Typography variant="body2">
                This patient has asthma as family history and he has consulted
                PCC in his last visits. Based on the current chief complaint he
                need a critical care of anthropology
              </Typography>
            </BoxStyle>{" "}
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <Typography marginTop={2} variant="body1" fontWeight={"bold"}>
              Next Actions for this patient
            </Typography>
            <BoxStyle>
              <Typography variant="body2" component={"div"} className="icon">
                <RadiobulletIcon fontSize={"24"} />
                Need a Image test
              </Typography>
              <Typography variant="body2" component={"div"} className="icon">
                <RadiobulletIcon fontSize={"24"} />
                Need Blood report
              </Typography>
              <Typography variant="body2" component={"div"} className="icon">
                <RadiobulletIcon fontSize={"24"} />
                Prescribe medications
              </Typography>
            </BoxStyle>
          </Grid>
        </Grid>
        <Typography marginTop={2} variant="body1" fontWeight={"bold"}>
          Timeline
        </Typography>
        <BoxStyle>
          <Box style={{ overflow: "auto", height: 400 }}>
            <PatientTimeline />
          </Box>
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              gap: 2,
              justifyContent: "center",
            }}
            alignItems={{ xs: "flex-start", md: "center" }}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Typography variant="body2" component={"div"} className="icon">
              <Circle color="error" fontSize="small" />
              <Typography variant="body2" component={"div"}>
                Emergencies Encounters
              </Typography>
            </Typography>
            <Typography variant="body2" component={"div"} className="icon">
              <Circle color="warning" fontSize="small" />
              <Typography variant="body2" component={"div"}>
                Orange - Outpatients visits (includes future visits if available
                from the source systems)
              </Typography>
            </Typography>
            <Typography variant="body2" component={"div"} className="icon">
              <Circle color="primary" fontSize="small" />
              <Typography variant="body2" component={"div"}>
                Inpatients Admissions
              </Typography>
            </Typography>
          </Box>
        </BoxStyle>
        <Grid container spacing={2} marginTop={1}>
          {OtherLinks?.map(({ Icon, link, name }: any) => (
            <Grid item xs={4} md={3} key={name}>
              <IconLink to={link}>
                <Icon />
                <Typography variant="body2" component={"div"}>
                  {name}
                </Typography>
              </IconLink>
            </Grid>
          ))}
        </Grid>
      </PatientDetailsStyle>
    </>
  );
}

import { Box, Grid, Typography, LinearProgress } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useParams } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  callAppointment,
  callEachPatientsListEndPoint,
} from "../../utils/endPoints";
import { FrmLbl } from "../../theme";
import { useState } from "react";

export default function DemographicsInfo() {
  const [detailsState, setDetailsState] = useState("general");
  const params: any = useParams();
  const { data, isLoading, isError, failureReason }: any = useQuery({
    queryKey: ["appointment", params],
    queryFn: () => callAppointment(params),
    placeholderData: keepPreviousData,
    staleTime: 10000,
  });

  const {
    isError: patientIsError,
    isInitialLoading: patientLoading,
    data: patientData,
    failureReason: patientFailureReason,
  }: any = useQuery({
    queryKey: ["patients", data?.[0]?.puuid],
    queryFn: () => callEachPatientsListEndPoint(data?.[0]?.puuid),
    staleTime: 10000,
    enabled: !!data,
  });

  if (isError || patientIsError) {
    return (
      <Typography variant="subtitle2">
        {isError ? failureReason.message : patientFailureReason?.message}
      </Typography>
    );
  }

  return (
    <>
      <Box className="DemographicsInfo">
        {(isLoading || patientLoading) && (
          <LinearProgress style={{ width: "100%" }} />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 1,
            opacity: isLoading ? 0.5 : 1,
          }}
        >
          <Grid
            container
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            spacing={3}
          >
            <Grid item xs={4}>
              <FrmLbl>
                <Typography variant="body2" component={"label"}>
                  Patient ID:
                </Typography>
                <Typography variant="body2" component={"span"}>
                  {patientData?.data?.pid ? patientData?.data?.pid : "NA"}
                </Typography>{" "}
              </FrmLbl>
            </Grid>
            <Grid item xs={4}>
              <FrmLbl>
                <Typography variant="body2" component={"label"}>
                  Language:
                </Typography>
                <Typography variant="body2" component={"span"}>
                  {patientData?.data?.language
                    ? patientData?.data?.language
                    : "NA"}{" "}
                </Typography>{" "}
              </FrmLbl>
            </Grid>
            <Grid item xs={4}>
              <FrmLbl>
                <Typography variant="body2" component={"label"}>
                  Ethnicity:
                </Typography>
                <Typography variant="body2" component={"span"}>
                  {patientData?.data?.ethnicity
                    ? patientData?.data?.ethnicity
                    : "NA"}{" "}
                </Typography>{" "}
              </FrmLbl>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{}}
                marginBottom={3}
                paddingTop={1}
                paddingBottom={1}
                borderBottom={"1px solid #ccc"}
                variant="body1"
                color="primary"
                fontWeight={600}
                component={"div"}
              >
                Patient Contact Information:
              </Typography>
              <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={3}
              >
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Name:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.fname} {patientData?.data?.lname}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      DOB:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.DOB ? patientData?.data?.DOB : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Gender:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.sex ? patientData?.data?.sex : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Marital Status:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.status
                        ? patientData?.data?.status
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Address Line 1:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.addresses[0]?.line1
                        ? patientData?.data?.addresses[0]?.line1
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Address Line 2:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.addresses[0]?.line2
                        ? patientData?.data?.addresses[0]?.line2
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      City:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.addresses[0]?.city
                        ? patientData?.data?.addresses[0]?.city
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      State:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.addresses[0]?.state
                        ? patientData?.data?.addresses[0]?.state
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Country:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.addresses[0]?.country_code
                        ? patientData?.data?.addresses[0]?.country_code
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Postal Code:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.addresses[0]?.postal_code
                        ? patientData?.data?.addresses[0]?.postal_code
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Phone:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.phone_biz
                        ? patientData?.data?.phone_biz
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Email:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.email
                        ? patientData?.data?.email
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                marginBottom={3}
                paddingTop={1}
                paddingBottom={1}
                borderBottom={"1px solid #ccc"}
                variant="body1"
                color="primary"
                fontWeight={600}
                component={"div"}
              >
                Patient's Employer Contact Info:
              </Typography>
              <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={3}
              >
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Name:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.employer_data?.[0]?.name ?? "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>

                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Occupation:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.occupation ?? "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Address:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.employer_data?.[0]?.street ?? "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      City:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.employer_data?.[0]?.city ?? "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      State:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.employer_data?.[0]?.state ?? "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Country:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.employer_data?.[0]?.country ?? "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Postal Code:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.employer_data?.[0]?.postal_code ??
                        "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>

                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Industry:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.industry ?? "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                marginBottom={3}
                paddingTop={1}
                paddingBottom={1}
                borderBottom={"1px solid #ccc"}
                variant="body1"
                color="primary"
                fontWeight={600}
                component={"div"}
              >
                Patient's Guardian Contact Info:
              </Typography>
              <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={3}
              >
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Name:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.guardiansname
                        ? patientData?.data?.guardiansname
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>

                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Gender:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.guardiansex
                        ? patientData?.data?.guardiansex
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Relationship:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.guardianrelationship
                        ? patientData?.data?.guardianrelationship
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Address:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.guardianaddress
                        ? patientData?.data?.guardianaddress
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      City:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.guardiancity
                        ? patientData?.data?.guardiancity
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      State:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.guardianstate
                        ? patientData?.data?.guardianstate
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Country:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.guardiancountry
                        ? patientData?.data?.guardiancountry
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Postal Code:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.guardianpostalcode
                        ? patientData?.data?.guardianpostalcode
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Phone:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.guardianphone
                        ? patientData?.data?.guardianphone
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FrmLbl>
                    <Typography variant="body2" component={"label"}>
                      Email:
                    </Typography>
                    <Typography variant="body2" component={"span"}>
                      {patientData?.data?.guardianemail
                        ? patientData?.data?.guardianemail
                        : "NA"}
                    </Typography>
                  </FrmLbl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup
                className="Tabsbtns"
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  onClick={() => setDetailsState("general")}
                  className={detailsState === "general" ? "active" : ""}
                  size="small"
                  variant="outlined"
                >
                  General
                </Button>
                <Button
                  onClick={() => setDetailsState("family_history")}
                  className={detailsState === "family_history" ? "active" : ""}
                  variant="outlined"
                >
                  {" "}
                  Family History
                </Button>
                <Button
                  onClick={() => setDetailsState("life_style")}
                  className={detailsState === "life_style" ? "active" : ""}
                  variant="outlined"
                >
                  {" "}
                  Lifestyle
                </Button>
              </ButtonGroup>
            </Grid>
            {detailsState === "general" ? (
              <>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{}}
                    marginBottom={3}
                    paddingBottom={1}
                    borderBottom={"1px solid #ccc"}
                    variant="body1"
                    color="primary"
                    fontWeight={600}
                    component={"div"}
                  >
                    Risk Factors
                  </Typography>
                  <Grid
                    container
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    spacing={3}
                  >
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Varicose Veins:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Fibroids:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Hypertension:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          PID:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Diabetes:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Severe Migraine:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Sickle Cell:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Heart Disease:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          PID (Pelvic Inflammatory Disease):
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Thrombosis/Stroke:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Hepatitis:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Gall Bladder Condition:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Breast Disease:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Depression:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Allergies:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Infertility:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Asthma:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Epilepsy:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Contact Lenses:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Contraceptive Complication:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Others:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    marginBottom={3}
                    paddingBottom={1}
                    borderBottom={"1px solid #ccc"}
                    variant="body1"
                    color="primary"
                    fontWeight={600}
                    component={"div"}
                  >
                    Exams/Tests
                  </Typography>
                  <Grid
                    container
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    spacing={3}
                  >
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Breast Exam:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          ECG:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Mammogram:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Prostate Exam:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Rectal Exam:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Sigmoid/Colonoscopy:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Retinal Exam:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Flu Vaccination
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          LDL
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Cardiac Echo:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Pneumonia Vaccination:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Gynecological Exam:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Hemoglobin:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          Physical Exam:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                    <Grid item xs={6}>
                      <FrmLbl>
                        <Typography variant="body2" component={"label"}>
                          PSA:
                        </Typography>
                        <Typography variant="body2" component={"span"}>
                          XXX{" "}
                        </Typography>
                      </FrmLbl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : detailsState === "family_history" ? (
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  spacing={3}
                >
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Father:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Mother:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Siblings:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Spouse:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Off Spring:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  spacing={3}
                >
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Tobacco:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Coffee:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Alcohol:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Recreational Drug:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Counseling:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Exercise Patterns:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Hazardous Activities:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Sleep Patterns:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                  <Grid item xs={6}>
                    <FrmLbl>
                      <Typography variant="body2" component={"label"}>
                        Seatbelt Use:
                      </Typography>
                      <Typography variant="body2" component={"span"}>
                        XXX{" "}
                      </Typography>
                    </FrmLbl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>{" "}
      </Box>
    </>
  );
}

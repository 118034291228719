import { CssBaseline, Grid, Typography, Card } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import {
  NotificationsIcon,
  PatientsIcon,
  PrescriptionsIcon,
  ReportsIcon,
  FlowboardIcon,
  NextvisitsIcon,
  AddressbookIcon,
  AppointmentsIcon,
} from "../../assets/icons";
import AppWidgetSummary from "../../components/AppWidgetSummary";

export const UserStyle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  padding: "30px 30px 0px",
  gap: 10,
  "& .MuiTypography-root": {
    margin: 0,
  },
  "& .MuiAvatar-root": {
    background: theme.palette.primary.main,
    color: "#fff",
    width: 50,
    height: 50,
    border: "2px solid " + theme.palette.primary.light,
  },
  [theme.breakpoints.down("md")]: {
    background: "#446CBA",
    color: "#fff",
    paddingBottom: 80,
    marginBottom: -80,
    width: "100vw",
    position: "relative",
    zIndex: 0,
    "&:after": {
      position: "absolute",
      content: `""`,
      background: "#e9eef5",
      width: "100%",
      height: 50,
      bottom: -25,
      left: 0,
      borderRadius: 50,
    },
  },
}));
export const CardStyle = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: 160,
  display: "flex",
  flexDirection: "column",
  borderRadius: 20,
  padding: 25,
  gap: 2,
  boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)",
  border: "1px solid #A3BBEB",
  "& h2": {
    fontSize: 18,
    fontWeight: 400,
  },
  "& .count": {
    fontSize: 30,
    fontWeight: 600,
  },
  "& .avatar": {
    background: "#557ac126",
    padding: 2,
    borderRadius: "50%",
    width: 70,
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
}));
export const IconTextStyle = styled(Card)(({ theme }) => ({
  transition: "all 0.5s ease-out",
  cursor: "pointer",
  width: "100%",
  minWidth: 160,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 20,
  padding: 25,
  gap: 5,
  background: "#446CBA16",
  boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)",
  border: "1px solid #446CBA16",
  marginBottom: 5,
  [theme.breakpoints.up("md")]: {
    gap: 5,
    marginBottom: 30,
  },
  "&:hover ": {
    background: "#fff",
    transform: "scale(1.01)",
    boxShadow: "0 0 10px 2px #48abe066",
  },
}));
export const DTIconStyle = styled("div")(({ theme }) => ({
  background: theme.palette.primary.main,
  width: "100%",
  minWidth: 160,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 25,
  gap: 5,
}));
export const MbIconStyle = styled("div")(({ theme }) => ({
  background: theme.palette.primary.main,
  width: "100%",
  minWidth: 160,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 25,
  gap: 5,
}));
export const ContentAreaStyle = styled("div")(({ theme }) => ({
  width: "100%",
  height: "90vh",
  minWidth: 160,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "25px 15px",
  [theme.breakpoints.down("md")]: {},
}));
export default function Dashboard() {
  const navigate = useNavigate();
  return (
    <>
      <CssBaseline />
      <Grid container>
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to="/app/prescriber-profile"
        >
          <UserStyle>
            <Avatar>
              {
                localStorage
                  .getItem("providerName")
                  ?.toString()
                  ?.toUpperCase()[0]
              }
            </Avatar>
            <Typography variant="body1" sx={{ mb: 5 }}>
              Welcome <br />
              <Typography variant="subtitle1" component={"span"} sx={{ mb: 5 }}>
                {localStorage.getItem("providerName")}
              </Typography>
            </Typography>
          </UserStyle>
        </Link>
      </Grid>
      <ContentAreaStyle>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          spacing={3}
        >
          <Grid item xs={6} sm={6} md={3}>
            <AppWidgetSummary
              onClick={() => navigate("/app/appointments/upcoming")}
              title="Appointments"
              text="Today"
              total={23}
              icon={<AppointmentsIcon />}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <AppWidgetSummary
              onClick={() => navigate("/app/notifications")}
              title="Notifications"
              text="Unread"
              total={33}
              icon={<NotificationsIcon />}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <AppWidgetSummary
              onClick={() => navigate("/app/patients")}
              title="Patients"
              text="Accounts"
              total={32}
              icon={<PatientsIcon />}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <AppWidgetSummary
              onClick={() => navigate("/app/prescriptions")}
              title="Prescriptions"
              text="Unread"
              total={23}
              icon={<PrescriptionsIcon />}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <IconTextStyle onClick={() => navigate("/app/reports")}>
              <ReportsIcon />
              <Typography variant="body2" component="div">
                Reports
              </Typography>
            </IconTextStyle>
          </Grid>{" "}
          <Grid item md={3} xs={6}>
            <IconTextStyle onClick={() => navigate("/app/flow-board")}>
              <FlowboardIcon />
              <Typography variant="body2" component="div">
                Flow Board
              </Typography>
            </IconTextStyle>
          </Grid>{" "}
          <Grid item md={3} xs={6}>
            <IconTextStyle onClick={() => navigate("/app/next-visits")}>
              <NextvisitsIcon />
              <Typography variant="body2" component="div">
                Next Visits
              </Typography>
            </IconTextStyle>
          </Grid>{" "}
          <Grid item md={3} xs={6}>
            <IconTextStyle onClick={() => navigate("/app/address-book")}>
              <AddressbookIcon />
              <Typography variant="body2" component="div">
                Address Book
              </Typography>
            </IconTextStyle>
          </Grid>
        </Grid>{" "}
      </ContentAreaStyle>
    </>
  );
}

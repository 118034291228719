import axios from "axios";
import { API_MEDIC, BASE_URL, YSI_DIAGNOSIS } from "../constants";
import Cookies from "js-cookie";
import { Buffer } from "buffer";

const api = axios.create({
  baseURL: BASE_URL,
});

const medic = axios.create({
  baseURL: API_MEDIC,
});

const diagnosis = axios.create({
  baseURL: YSI_DIAGNOSIS,
});

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("JWT_TOKEN");
    if (token) {
      // Configure this as per your backend requirements
      config.headers!["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getCall = async (url: string) => {
  try {
    const response = await api.get(url, {
      headers: {
        // Authorization: TOKEN ? `Bearer ${TOKEN}` : "",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const medicGetCall = async (url: string) => {
  try {
    const response = await medic.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postCall = async (url: string, data = {}) => {
  try {
    const response = await api.post(url, data, {
      headers: {
        // Authorization: TOKEN ? `Bearer ${TOKEN}` : "",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const postCallOfX = async (url: string, data = {}) => {
//   try {
//     const response = await api.post(url, data, {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//       },
//     });
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

export const postCallForYSIDiagnosis = async (url: string, data: any) => {
  try {
    const credentials = `${data?.username}:${data?.password}`;
    const encodedCredentials = Buffer.from(credentials).toString("base64");
    const token = Cookies.get("DIAGNOSIS_TOKEN");
    const response = await diagnosis.post(url, url === "getToken" ? {} : data, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          credentials && url === "getToken"
            ? `Basic ${encodedCredentials}`
            : `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Tab,
  LinearProgress,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { theme } from "../../theme";
import PlusIcon from "@mui/icons-material/AddCircle";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTests } from "../../queries/useTests";

export default function DiagnosisTests({
  setDiagnosisTestData,
  diagnosisTestData,
  selectedDiseaseId,
}: any) {
  const [selectedTabDT, setSelectedTabDT] = useState("Lab Tests");
  const handleChangeDT = (event: any, newValue: any) => {
    setSelectedTabDT(newValue);
  };
  const checkTestIsPresent = (options: any) => {
    return diagnosisTestData?.find((test: any) => test?.name === options);
  };
  const { data, isInitialLoading, error, isError }: any =
    useTests(selectedDiseaseId);

  return (
    <>
      <Card>
        <CardHeader
          title="Diagnosis Tests"
          action={
            <Button variant="text" size="small">
              Previous Tests
            </Button>
          }
        />{" "}
        <CardContent>
          <Typography
            variant="body2"
            component={"div"}
            fontWeight={600}
            style={{
              color: theme.palette.primary.main,
              padding: "3px 0",
              borderBottom: "1px solid " + theme.palette.primary.main,
              marginBottom: 10,
            }}
          >
            Filtered based on the diagnosis:
          </Typography>
          <TabContext value={selectedTabDT}>
            <TabList
              variant="fullWidth"
              className="DTTabs"
              sx={{ padding: "20px 20px 0 20px", height: "auto" }}
              onChange={handleChangeDT}
            >
              <Tab
                key={"1"}
                label={"Lab Tests"}
                iconPosition="start"
                value={"Lab Tests"}
              />
              <Tab
                key={"2"}
                label={"Imaging Tests"}
                iconPosition="start"
                value={"Imaging Tests"}
              />
            </TabList>
            <TabPanel className="DTTabPanel" value={"Lab Tests"}>
              <Box className="AddToList">
                {isInitialLoading ? (
                  <LinearProgress />
                ) : isError ? (
                  <Box>
                    <Typography variant="body2" component={"div"}>
                      {error?.message}
                    </Typography>
                  </Box>
                ) : data?.lab_tests?.length > 0 ? (
                  data?.lab_tests?.map((options: any) => (
                    <Box key={options}>
                      <AssignmentTurnedInIcon
                        color="primary"
                        fontSize="small"
                      />
                      <Typography variant="caption" component={"div"}>
                        {options}
                      </Typography>
                      <Button
                        variant="text"
                        size="small"
                        className="xs"
                        // disabled={diagnosisTestData?.some(
                        //   (option: any) => option.name === options
                        // )}
                        startIcon={
                          checkTestIsPresent(options) ? (
                            <RemoveCircleIcon />
                          ) : (
                            <PlusIcon />
                          )
                        }
                        onClick={() => {
                          if (checkTestIsPresent(options)) {
                            setDiagnosisTestData(
                              diagnosisTestData?.filter(
                                (test: any) => test?.name !== options
                              )
                            );
                          } else {
                            setDiagnosisTestData((prev: any) => [
                              ...prev,
                              {
                                name: options,
                              },
                            ]);
                          }
                        }}
                      >
                        {checkTestIsPresent(options)
                          ? "Remove"
                          : "Add to RxPad"}
                      </Button>
                    </Box>
                  ))
                ) : (
                  <Box>
                    <Typography variant="caption" component={"div"}>
                      Not Available
                    </Typography>
                  </Box>
                )}
              </Box>
            </TabPanel>
            <TabPanel className="DTTabPanel" value={"Imaging Tests"}>
              <Box className="AddToList">
                {isInitialLoading ? (
                  <LinearProgress />
                ) : isError ? (
                  <Box>
                    <Typography variant="body2" component={"div"}>
                      {error?.message}
                    </Typography>
                  </Box>
                ) : data?.image_tests?.length > 0 ? (
                  data?.image_tests?.map((options: any) => (
                    <Box key={options}>
                      <AssignmentTurnedInIcon
                        color="primary"
                        fontSize="small"
                      />
                      <Typography variant="caption" component={"div"}>
                        {options}
                      </Typography>
                      <Button
                        variant="text"
                        size="small"
                        className="xs"
                        // disabled={diagnosisTestData?.some(
                        //   (option: any) => option.name === options
                        // )}
                        startIcon={
                          checkTestIsPresent(options) ? (
                            <RemoveCircleIcon />
                          ) : (
                            <PlusIcon />
                          )
                        }
                        onClick={() => {
                          if (checkTestIsPresent(options)) {
                            setDiagnosisTestData(
                              diagnosisTestData?.filter(
                                (test: any) => test?.name !== options
                              )
                            );
                          } else {
                            setDiagnosisTestData((prev: any) => [
                              ...prev,
                              {
                                name: options,
                              },
                            ]);
                          }
                        }}
                      >
                        {checkTestIsPresent(options)
                          ? "Remove"
                          : "Add to RxPad"}
                      </Button>
                    </Box>
                  ))
                ) : (
                  <Box>
                    <Typography variant="caption" component={"div"}>
                      Not Available
                    </Typography>
                  </Box>
                )}
              </Box>
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
    </>
  );
}

import { Box, Typography, Card, CardHeader, CardContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useState } from "react";
import { useParams } from "react-router-dom";

const columnsMyReports = [
  {
    field: "reportDetails",
    headerName: "Report Details",
    width: 200,
    renderCell: (params: any) => {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 1,
              gap: 1,
            }}
          >
            <Typography variant="body2" component={"div"}>
              {params.row.reportDetails[1].type}
            </Typography>{" "}
            <Typography variant="body2" component={"div"}>
              {params.row.reportDetails[0].name}
            </Typography>
          </Box>
        </>
      );
    },
  },
  {
    field: "date",
    headerName: "Date",
    minWidth: 150,
  },
  {
    field: "approvedby",
    headerName: "Approved By",
    minWidth: 150,
    flex: 1,
  },
];
const rowsMyReports = [
  {
    id: 1,
    reportDetails: [
      { name: "10-Jul-2023-09:22pm-00234234234243.pdf" },
      { type: "Lab Report" },
    ],
    date: "10-Jul-2023 09:22 PM",
    approvedby: "Associate Portal",
  },
  {
    id: 2,
    reportDetails: [
      { name: "12-Jun-2023-11:22pm-324323423423423.pdf" },
      { type: "Lab Report" },
    ],

    date: "12-Jun-2023 11:22 PM",
    approvedby: "Associate Portal",
  },
  {
    id: 3,
    reportDetails: [
      { name: "ConsultationNotes-2342324234234243.pdf" },
      { type: "Prescriptions" },
    ],

    date: "18-Jul-2023 10:10 PM",
    approvedby: "Dr Roger Scheirman",
  },
];
export default function MyReports() {
  const { pid } = useParams();
  const [reportState, setReportState] = useState("by_other");

  return (
    <>
      <Card>
        <CardHeader title="My Reports" />
        <CardContent>
          <ButtonGroup style={{ marginBottom: 15 }}>
            <Button
              onClick={() => setReportState("by_other")}
              variant={reportState === "by_other" ? "contained" : "outlined"}
              size="small"
            >
              By Others(3)
            </Button>{" "}
            <Button
              onClick={() => setReportState("by_patient")}
              variant={reportState === "by_patient" ? "contained" : "outlined"}
              size="small"
            >
              By Patient(0)
            </Button>
          </ButtonGroup>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              rows={rowsMyReports}
              columns={columnsMyReports}
              hideFooter
              autoPageSize
              columnHeaderHeight={30}
              getRowHeight={() => "auto"}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
}

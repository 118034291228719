import { createTheme } from "@mui/material";
import searchIcon from "../assets/images/search-icon.svg";
import deleteIcon from "../assets/images/delete-icon.svg";
import checkboxIcon from "../assets/images/checkbox-icon.svg";
import checkedboxIcon from "../assets/images/checkedbox-icon.svg";
import { styled } from "@mui/material";
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      dark: "#050c15",
      light: "#eaf1fa",
      main: "#464EA0",
    },
    secondary: {
      main: "#7d8db6",
    },
    success: {
      main: "#33B469",
    },
    warning: {
      main: "#E59E33",
    },
    info: {
      main: "#2F80ED",
    },
    error: {
      main: "#E51414",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "& .osdate ": {
          "&   .MuiStack-root": {
            overflow: "visible",
          },
          "&   .MuiDateCalendar-root": {
            padding: 10,
            borderRadius: 6,
            background: "#fff",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
          },
        },
        "& .MuiBox-root.TopLeftRightRadius": {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          background: "#fff",
          height: 20,
          position: "relative",
          "&:before ": {
            content: `""`,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            background: "linear-gradient(124deg, #547db21f 0%, #819ec530 100%)",
          },
        },
        "& .checkboxes ul": {
          flexDirection: "column",
          padding: "5px !Important",
          "& li[data-option-index]": {
            background: "#fff !Important",
            color: "#1A4176 !Important",
            padding: "5px 10px 5px 30px !important",
          },

          '& li[data-option-index][aria-selected="false"]': {
            background: `url('${checkboxIcon}') no-repeat 8px center !important`,
            color: "#666 !important",
          },
          '& li[data-option-index][aria-selected="true"]': {
            background: `url('${checkedboxIcon}') no-repeat 8px center !important`,
            color: "#1A4176 !important",
          },
        },
        "& textarea": {
          border: "1px solid #1A417633",
          borderRadius: 10,
        },
        "& .MuiInputBase-input.MuiOutlinedInput-input, & .MuiOutlinedInput-input":
          {
            boxSizing: "border-box",
          },
        "& .MuiInputLabel-shrink.Mui-focused": {
          background: "#fff",
          padding: "0 3px",
          borderRadius: 10,
        },
        "& .MuiDialog-paper": {
          borderRadius: "10px !important",
        },
        "*::-webkit-scrollbar": {
          width: 6,
          height: 6,
          borderRadius: 5,
        },
        "*::-webkit-scrollbar-track ": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          backgroundColor: " #ffffff33",
          borderRadius: 5,
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "#B9B9B9",
          borderRadius: 5,
        },
        "& .apts  ": {
          overflow: "auto",
          "@media(max-width:567.98px)": {
            //    height: "calc(100vh - 28%)",
          },
          borderRadius: "20px 20px 0 0",
          minHeight: "80vh",
          paddingLeft: 0,
          paddingRight: 0,
        },
        "& .InsuranceInfo, & .DemographicsInfo": {
          background: "#fff",
          overflow: "auto",
          "@media(max-width:567.98px)": {
            //    height: "calc(100vh - 28%)",
          },
        },
        "& .MuiTabPanel-root.ApptTabPanel": {
          overflow: "auto",
          "@media(max-width:567.98px)": {
            padding: 16,
            //     height: "calc(100vh - 28%)",
          },
        },
        "& .AddToList": {
          marginTop: 5,
          overflow: "auto",
          maxHeight: 120,
          gap: 2,
          display: "flex",
          flexDirection: "column",
          "& .MuiBox-root:nth-of-type(odd)": {
            background: "#f2f2f2",
          },
          "& .MuiTypography-root": {
            width: "80%",
          },
          "& .MuiBox-root": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: ".5px solid #bcbcbc",
            padding: "0 10px",
            borderRadius: 3,
            "& .MuiTypography-root": {
              margin: 3,
            },
            "& .MuiButton-textSizeSmall.xs": {
              minWidth: 120,
              height: "auto",
            },
          },
        },
        "&   .MuiTabPanel-root.DTTabPanel": {
          background: "#fff",

          padding: 2,
          "@media(max-width:567.98px)": {
            //     height: "calc(100vh - 28%)",
          },
        },
        "& .MuiDataGrid-root  ": {
          border: "none !Important",
          "&   .MuiDataGrid-main .MuiDataGrid-columnHeaders ": {
            border: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            border: "none !Important",
          },
          "&   .MuiDataGrid-row ": {
            borderRadius: 5,
            marginTop: 2,
            "&:nth-of-type(odd)   .MuiDataGrid-cell   ": {
              background: "#f2f2f2",
            },
            "&   .MuiDataGrid-cell   ": {
              borderTop: "1px solid #ccc !Important",
              borderBottom: "1px solid #ccc !Important",
            },
            "&   .MuiDataGrid-cell.first    ": {
              borderLeft: "1px solid #ccc !Important",
              borderRadius: "5px 0 0 5px",
            },
            "&   .MuiDataGrid-cell.last   ": {
              borderRight: "1px solid #ccc !Important",
              borderRadius: "0 5px 5px 0",
              marginLeft: -2,
            },
          },
        },
        "& label": {
          fontSize: 14,
        },
        "& #root ": {
          background: "linear-gradient(124deg, #547db21f 0%, #819ec530 100%)",

          "&  .bottomspacebar": {
            minHeight: 40,
          },
          "@media(min-width:992px)": {
            "&  .bottomspacebar": {
              display: "none",
            },
          },
        },
        "&  .notifications ": {
          "&  .MuiBadge-badge ": {
            background: "#ff5656",
          },
        },
        "& div ,  &  p.MuiTypography-root,  & h1.MuiTypography-root,  & h2.MuiTypography-root,  & h3.MuiTypography-root,  & h4.MuiTypography-root,  & h5.MuiTypography-root, & h6.MuiTypography-root, & label.MuiTypography-root, & span.MuiTypography-root, & div.MuiTypography-root, & p , & button, & input, & select, & textarea, button.MuiButton-root    ":
          {
            fontFamily: [
              '"Noto Sans"',
              "-apple-system",
              "BlinkMacSystemFont",
              "Roboto",
              '"Helvetica Neue"',
              "Arial",
              "sans-serif",
            ].join(","),
          },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "normal",
          "&.today": {},
          "&.subtitle1": {
            fontSize: 24,
            fontWeight: 200,
            marginTop: 10,
            marginBottom: 10,
          },
        },
        h1: {
          fontSize: 48,
          fontWeight: 700,
        },
        h2: {
          fontSize: 40,
          fontWeight: 700,
          marginBottom: 20,
        },
        h3: {
          fontSize: 32,
          fontWeight: 700,
        },
        h4: {
          fontSize: 28,
          fontWeight: 700,
        },
        h5: {
          fontSize: 24,
          fontWeight: 700,
        },
        h6: {
          fontSize: 20,
          fontWeight: 500,
          marginBottom: 20,
        },
        subtitle1: {
          fontSize: 18,
          fontWeight: 600,
        },
        subtitle2: {
          fontSize: 16,
          fontWeight: 400,
        },
        body1: {
          fontWeight: 400,
          fontSize: 16,
        },
        body2: {
          fontWeight: 400,
          fontSize: 14,
        },
        button: {
          fontWeight: 400,
          fontSize: 16,
        },
        caption: {
          fontSize: 12,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 5,
          "& .MuiMenuItem-root ": { paddingLeft: 10, paddingRight: 10 },
          "@media(max-width:991.98px)": {
            "&.navmenu": {
              "& .MuiListItemText-root ": { display: "none" },
              "& .MuiSvgIcon-root": {
                fill: "#1A4176",
              },
              position: "fixed",
              display: "flex",
              justifyContent: "space-between",
              bottom: 0,
              width: "100%",
              background:
                "linear-gradient(1deg, rgb(229 240 255) 0%, rgb(255 255 255) 100%)",
            },
          },
          "&.list": {
            overflow: "auto",
            maxHeight: 200,
            "& .MuiListItem-root   ": {
              padding: 0,
              alignItems: "flex-start",
              "&   .MuiListItemIcon-root": {
                minWidth: 20,
                "&   .MuiSvgIcon-root": {
                  width: 16,
                },
              },
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40,
          height: 40,
          borderRadius: 6,
          background: "#446CBA",
          "&.DTTabs": {
            minHeight: 30,
            height: 30,
            background: "#eaf1fa",
            marginTop: 5,
            padding: 0,
            "& .MuiTabs-indicator": {
              height: 30,
              background: "#1a4176",
              borderRadius: 6,
            },
            "& .MuiTab-root ": {
              textTransform: "capitalize",
              height: 30,
              minHeight: 30,
              background: "transparent !important",
              color: "#666666",
              padding: "5px 10px",
              border: "none",
              "& svg  ": {
                width: 20,
                height: 20,
                marginRight: 3,
                "&   rect": {
                  fill: "#aaa",
                },
              },
              "&.Mui-selected": {
                "& svg rect": {
                  fill: "#557AC2",
                },
                zIndex: 1,
                color: "#fff",
              },
            },
          },
          "&.ApptTabs": {
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 0,
            "& .MuiTabs-indicator": {
              height: 3,
              background: "#1a4176",
              borderRadius: 6,
            },
            "& .MuiTab-root:first-of-type": {
              borderTopLeftRadius: 20,
            },
            "& .MuiTab-root:last-of-type": {
              borderTopRightRadius: 20,
            },
            "& .MuiTab-root ": {
              textTransform: "capitalize",
              height: 40,
              background: "#fff",
              color: "#666666",
              padding: "5px 10px",
              border: "none",
              "& svg  ": {
                marginRight: 3,
                background: "#999",
                borderRadius: 6,
                fontSize: 16,
                "&   rect": {
                  fill: "#aaa",
                },
              },
              "&.Mui-selected": {
                "& svg  ": {
                  background: "#1A4176",
                },
                "& svg rect": {
                  fill: "#557AC2",
                },
                zIndex: 1,
                color: "#000",
                borderBottom: "2px solid #557AC2",
              },
            },
          },
          "@media(max-width:767.98px)": {
            "& .MuiTabs-flexContainer": {
              borderRadius: 6,
            },
          },

          "& .MuiTab-root ": {
            color: "#fff",
            textTransform: "capitalize",
            padding: 6,
            minHeight: 40,
            "&.Mui-selected": {
              color: "#fff",
              zIndex: 1,
            },
          },

          "& .MuiTabs-indicator": {
            height: 40,
            background: "#1a4176",
            borderRadius: 6,
          },
        },
      },
    },

    MuiButtonGroup: {
      styleOverrides: {
        root: {
          "&.DWM   ": {
            "@media(min-width:991.98px)": {
              display: "flex",
              justifyContent: "flex-end",
            },
            background: "transparent",
            minHeight: 30,
            height: "auto",
            paddingRight: 10,
            "& .MuiButtonGroup-grouped:not(:last-of-type)": {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
            "& .MuiButtonGroup-grouped:not(:first-of-type)": {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },

            "& .MuiButton-root": {
              textTransform: "capitalize",
              height: 30,
              background: "#1a41762b",
              borderRadius: 3,
              "&.active": {
                background: "#fff",
                color: "#1a4176",
              },
            },
          },
          "&.ApptTabs   ": {
            display: "flex",
            position: "relative",
            "& .MuiButtonGroup-grouped:first-of-type": {
              borderTopLeftRadius: 20,
            },
            "& .MuiButtonGroup-grouped:last-of-type": {
              borderTopRightRadius: 20,
            },

            "& .MuiButtonGroup-grouped:not(:last-of-type)": {
              borderBottomLeftRadius: 0,
            },
            "& .MuiButtonGroup-grouped:not(:first-of-type)": {
              borderBottomRightRadius: 0,
            },
            "& .MuiButton-root": {
              textTransform: "capitalize",
              height: 40,
              background: "#f2f2f2",
              color: "#666666",
              padding: "5px 10px",
              width: "34%",
              border: "none",
              "&.active": {
                color: "#000",

                borderBottom: "2px solid #557AC2",
                "& .MuiAvatar-root": {
                  background: "#557AC2",
                },
              },
              "& .MuiAvatar-root": {
                fontSize: 12,
                marginRight: 5,
                width: 20,
                height: 20,
                background: "#AAAAAA",
              },
            },
          },
          "&.Tabsbtns   ": {
            display: "flex",
            position: "relative",
            paddingTop: 2,
            gap: 2,
            "& .MuiButton-root": {
              textTransform: "capitalize",
              height: 40,
              background: "#f2f2f2",
              color: "#666666",
              padding: "5px 10px",
              borderRadius: 5,
              width: "34%",
              border: "none",
              lineHeight: "normal",
              "&.active": {
                color: "#1A4176",
                background: "#1A417622",
              },
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: "yellow",
          },

          "&:hover": {
            backgroundColor: "#9c27b0",
          },
          backgroundColor: "#fff",
        },
        popper: {
          "& ul": {
            padding: 15,
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: 5,
            boxSizing: "border-box",
          },
          "& li[data-option-index]": {
            position: "relative",
            background: "#1A4176",
            color: "#fff",
            borderRadius: 5,
            padding: "5px 10px",
            lineHeight: "normal",
            minHeight: "inherit",
            border: "1px solid #1A4176",
            height: "auto",
          },

          '& li[data-option-index][aria-selected="false"]': {
            background: "#1A4176",
            color: "#fff",
          },
          '& li[data-option-index][aria-selected="true"]': {
            background: "#fff",
            color: "#1A4176",
          },
          // '& li:before': {
          //   position:'absolute',
          //   content: '""',
          //   width: 14,
          //   height: 15,
          //   left:0,
          //   top:0,
          // },
          // '& li[data-option-index][aria-selected="true"]::before': {
          //   backgroundImage: `url(${checkedboxIcon})`,
          // },
          // '& li[data-option-index][aria-selected="false"]::before': {
          //   backgroundImage: `url(${uncheckboxIcon})`,
          // },
        },
        root: {
          "& .MuiAutocomplete-input, &.MuiAutocomplete-inputRoot": {
            padding: 6,
            minHeight: 20,
          },
          "& .MuiAutocomplete-endAdornment .MuiIconButton-root.MuiAutocomplete-popupIndicator ":
            {
              background: `url('${searchIcon}') center no-repeat`,
              backgroundSize: "80%",
              transform: "rotate(0deg) !important",
              "&  svg": {
                visibility: "hidden",
              },
            },
          "&  .Mui-focused fieldset   ": {
            top: -5,
          },
          "& .MuiInputBase-root:hover fieldset": {
            top: -5,
          },
          "& .MuiFormLabel-root.MuiInputLabel-root ": {
            transform: "translate(14px, 12px) scale(1)",
          },

          "&.MuiAutocomplete-hasClearIcon ": {
            "& .MuiInputBase-root:hover fieldset": {
              top: -2,
            },

            "& .MuiFormLabel-root, & legend": {
              display: "none",
            },
          },
          "&  .MuiAutocomplete-tag": {
            borderRadius: 5,
          },

          "& .MuiAutocomplete-inputRoot": {
            background: "#fff",
            border: "1px solid #1A417633",
            height: "auto",
          },
          "&  .MuiChip-root": {},
          "&  .MuiChip-deleteIcon": {
            background: `url('${deleteIcon}') center no-repeat`,
            "&  path": {
              visibility: "hidden",
            },
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          height: 24,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          "&.white, &.whitebg": {
            background: "#fff",
          },
        },
        containedPrimary: {
          background:
            "linear-gradient(10deg, rgba(26,65,118,1) 0%, rgba(81,120,195,1) 100%)",
        },
        sizeLarge: {
          height: 46,
          borderRadius: 8,
          minWidth: 150,
        },
        sizeMedium: {
          height: 40,
          borderRadius: 6,
        },
        sizeSmall: {
          height: 30,
          borderRadius: 4,
        },
        text: {
          "&.xs": {
            fontSize: 12,
            alignItems: "center",
          },
          "&.whitetext": {
            color: "#fff",
          },
        },
        root: {
          lineHeight: "normal",
          textTransform: "none",
          minWidth: 100,
          boxSizing: "border-box",

          "& .MuiButton-startIcon": {
            marginRight: 5,
          },
          "& span.MuiButton-endIcon": {
            right: 20,
            position: "absolute",
          },
          "&.xs": {
            padding: 5,
            height: 22,
            fontSize: 13,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          },
          "&.wauto": {
            minWidth: "inherit",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          background: "linear-gradient(4deg, #d3dbe9 0%, #fff 100%)",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          padding: 20,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: "100%",
          boxShadow: " 0px 5px 8px 0px rgb(171 187 211 / 16%)",
          borderRadius: 15,
          border: "2px solid #ffffff",
          "@media(max-width:991.98px)": {
            overflow: "visible",
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: 18,
          fontWeight: 400,
          color: "#000",
        },
        root: {
          padding: "10px 16px",
          paddingBottom: 0,
          background:
            "linear-gradient(181deg, #fff 0%,  #ececec 50%, #fff 100%)",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 16,
          "&:last-child": {
            padding: 16,
          },
          "& .carousel-item": {
            paddingTop: 30,
            paddingBottom: 30,
          },
          "& .carousel-caption": {
            top: 0,
            bottom: "inherit",
            padding: 0,
            left: "inherit",
            right: "inherit",
          },
          "& .carousel-indicators": {
            bottom: 0,
            top: "inherit",
            padding: 0,
            marginBottom: 0,
          },

          "@media   (min-width:768px)": {
            "&.h220": {
              height: 220,
            },
            "&.h150": {
              height: 150,
            },
            "&.h100": {
              height: 100,
            },
          },

          "@media(max-width:991.98px)": {
            padding: 16,
            "&:last-child": {
              padding: 16,
            },
          },
          "&.flex": {
            display: "flex",
            gap: 5,
            flexWrap: "wrap",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-shrink.MuiInputLabel-root ": {
            transform: "translate(14px, -9px) scale(0.75)",
            background: "#fff",
            borderRadius: 6,
          },
          "& .MuiInputLabel-root ": {
            transform: "translate(14px, 8px) scale(1)",
          },
          marginTop: 0,
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            boxShadow: "inset 1px 1px 20px 13px white",
            padding: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "10px !Important",
          background: "#fff",
          boxSizing: "border-box",
          height: 40,
          border: "1px solid #333",

          "&:focus": {
            background: "#fff",
            "& .MuiInputAdornment-root": {
              background: "#fff",
            },
          },

          "&:focus-visible": {
            background: "#fff",
            border: "1px solid #1A4176",
            "& .MuiInputAdornment-root": {
              background: "#fff",
            },
          },
          "& .MuiInputAdornment-root": {
            zIndex: 1,
          },
          "& fieldset": {
            borderColor: "transparent",
          },
          "&:focus-visible fieldset": {
            background: "#fff",
            border: "1px solid #1A4176",
          },
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          margin: 3,
          background: "#fff",
          borderRadius: 5,
          border: "1px solid #1A417675",

          "@media(max-width:991.98px)": {
            background: "transparent",
            border: "1px solid transparent",
            justifyContent: "center",
          },
          "&:hover": {
            background: "#fff",
            border: "1px solid #1A4176",
            backgroundImage: `linear-gradient(180deg, #fff 0%, #eaf1fa 100%)`,
          },

          "@media(min-width:1024px)": {
            "&:hover": {
              background: "#fff",
              border: "1px solid #1A4176",
              backgroundImage: `linear-gradient(180deg, #fff 0%, #eaf1fa 100%)`,
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {},
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 46,
          "& svg  ": {
            width: 24,
            height: 24,
          },
          "& svg path": {
            stroke: "#1A4176",
          },
          "@media(max-width:991.98px)": {
            minWidth: "inherit",
            "& svg path": {
              stroke: "#1A4176",
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "56px !important",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlined: {
          background: "#fff",
        },
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded, & .MuiAccordionSummary-root": {
            borderRadius: 10,
          },
          width: "100%",
          borderRadius: 10,
          margin: "0 !important",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        },
      },
    },
  },
});

export const FrmLbl = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: 5,
  "& label.MuiTypography-body2 ": {
    color: "#666",
    fontWeight: 400,
    minWidth: 110,
  },
  "& .MuiTypography-body2 ": {
    color: "#000",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const IconLbl = styled("div")(({ theme }) => ({
  "& .MuiTypography-body2 ": {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
}));

export const GraphStyle = styled("div")(({ theme }) => ({
  transition: "all 0.5s ease-out",
  width: "100%",
  height: 200,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  gap: 10,
  padding: 10,
  border: "1px solid #ccc",
  borderRadius: 5,
  "& img": {
    width: "100%",
    maxHeight: "100%",
  },
  [theme.breakpoints.down("md")]: {
    height: 100,
  },
}));
